import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="piechart"
export default class extends Controller {
    static values = {
        totalWon: Number,
        totalGoal: Number
    }

    connect() {
        const data1 = {
            visibleForStick: true,
            labelColor: "#00000080",
            stickColor: "#00000080",
            data: [
                {
                    label: "Install Res",
                    value: 250000,
                    color: "#5800E780"
                },
                {
                    label: "Install Com",
                    value: 200000,
                    color: "#FF000080"
                },
                {
                    label: "Service Res",
                    value: 260000,
                    color: "#00FF1980"
                },
                {
                    label: "Service Com",
                    value: 250000,
                    color: "#FFE81A80"
                },
                {
                    label: "Cleanings",
                    value: 56756,
                    color: "#24FFE580"
                }
            ]
        };

        (() => {
            const container = this.element;
            const el = d3.select(container);
            const margin = el.node().offsetWidth / 6;
            const width = el.node().offsetWidth;
            const height = width - margin;

            const radius = Math.min(width, height) / 2 - margin;

            const svg = d3.select(container)
                .append("svg")
                .attr("width", width)
                .attr("height", height)
                .append("g")
                .attr("transform", `translate(${width / 2}, ${height / 2})`);

            function update(param) {
                const data = param.data;
                const pie = d3.pie()
                    .value(function (d) { return d["value"]; })
                    .sort(function (a, b) { return d3.ascending(a.key, b.key); });
                const data_ready = pie(data);

                const arcGenerator = d3.arc()
                    .innerRadius(width / 150)
                    .outerRadius(radius);

                svg
                    .selectAll('mySlices')
                    .data(data_ready)
                    .join('path')
                    .attr('d', arcGenerator)
                    .attr('fill', function (d) { return (d.data["color"]) })
                    .style("opacity", 1);

                if (param.visibleForStick) {
                    svg
                        .selectAll('mySlices')
                        .data(data_ready)
                        .join("line")
                        .attr(
                            "y1",
                            function (d) {
                                var c = arcGenerator.centroid(d),
                                    x = c[0],
                                    y = c[1],
                                    h = Math.sqrt(x * x + y * y);
                                return y / h * radius;
                            })
                        .attr(
                            "x1",
                            function (d) {
                                var c = arcGenerator.centroid(d),
                                    x = c[0],
                                    y = c[1],
                                    h = Math.sqrt(x * x + y * y);
                                return x / h * radius;
                            })
                        .attr(
                            "y2",
                            function (d) {
                                var c = arcGenerator.centroid(d),
                                    x = c[0],
                                    y = c[1],
                                    h = Math.sqrt(x * x + y * y);
                                return y / h * (radius + width / 25);
                            })
                        .attr(
                            "x2",
                            function (d) {
                                var c = arcGenerator.centroid(d),
                                    x = c[0],
                                    y = c[1],
                                    h = Math.sqrt(x * x + y * y);
                                return x / h * (radius + width / 25);
                            })
                        .attr("stroke", param.stickColor ? param.stickColor : "#000000");
                }
                let label = svg
                    .selectAll('mySlices')
                    .data(data_ready)
                    .join('text')
                    .text(function (d) { return d.data["label"] })
                    .attr("transform", function (d) {
                        var c = arcGenerator.centroid(d),
                            x = c[0],
                            y = c[1],
                            h = Math.sqrt(x * x + y * y),
                            labelr = radius + width / 20;
                        return "translate(" + (x / h * labelr) + ',' + (y / h * labelr) + ")";
                    })
                    .attr("text-anchor", function (d) {
                        return (d.endAngle + d.startAngle) / 2 > Math.PI ?
                            "end" : "start";
                    })
                    .style("font-size", width / 27)
                    .style("fill", param.labelColor ? param.labelColor : "#000000")

                label.append('tspan')
                    .text(function (d) { return `$${d.data["value"]}` })
                    .attr('x', '0')
                    .attr('y', '1.2em');
            }
            update(data1)
        })();
    }
}
