import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="body"
export default class extends Controller {
  static values = {
    baseUri: String
  };

  connect() {
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;

    document.querySelectorAll('input[name="authenticity_token"]').forEach(csrfInput => {
      csrfInput.value = csrfToken;
    });

    document.querySelectorAll('input[name="redirect_uri"]:not([data-allow-remote])').forEach(redirectUriInput => {
      //redirectUriInput.value = redirectUriInput.value.replace(/(https:\/\/|http:\/\/)?[\w\.:\-]+\//i, this.baseUriValue + '/');
    });
  }
}
