import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="pm-select"
export default class extends Controller {
  static targets = [ "nameBox" ];
  connect() {
  }

  selected(event) {
    let e = event.target;
    if(event.target.value != '') {
      this.nameBoxTarget.value = event.target.options[e.selectedIndex].text;
    } else {
      this.nameBoxTarget.value = '';
    }
  }
}
