import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="sort-link"
export default class extends Controller {
  static values = {
    orderByParam: { type: String, default: 'order_by' },
    orderDirectionParam: { type: String, default: 'order_direction' },
    orderBy: { type: String }
  };

  connect() {
    let url = new URL(document.location);
    
    if (url.searchParams.get(this.orderByParamValue) == this.orderByValue) {
      // This is the current sort - mark the link as such:
      let direction = url.searchParams.get(this.orderDirectionParamValue);
      direction ||= 'desc';
      this.element.classList.add(direction);

      // the new url should reverse the direction:
      if (direction == 'desc') {
        direction = 'asc'
      } else {
        direction = 'desc'
      }
      url.searchParams.set(this.orderDirectionParamValue, direction);
    } else {
      // This not the current sort, the new url should set it:
      url.searchParams.set(this.orderByParamValue, this.orderByValue);
      url.searchParams.set(this.orderDirectionParamValue, 'desc');
      //console.log(`No Match\n-- url param: ${this.orderByParamValue}\n-- params: ${url.searchParams}\n-- param value: ${paramValue}\n-- identifier: ${this.orderByValue}`);
    }

    this.element.setAttribute('href', url.toString());
  }
}
