import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="builders--typed-select"
export default class extends Controller {
  static targets = ['typeField'];

  connect() {
  }

  updateType() {
    this.typeFieldTarget.value = this.element.querySelector('select').options[this.element.querySelector('select').selectedIndex].dataset.className;
    //var text = e.options[e.selectedIndex].text;
    //var text = e.options[e.selectedIndex].text;
  }
}
