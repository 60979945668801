import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="accept-contract"
export default class extends Controller {
  static targets = [ 'acceptButton', 'signature', 'acceptTerms' ];
  connect() {
  }

  toggle() {
    let enable = this.acceptTermsTarget.checked && this.signatureTarget.value.length > 0 && this.signatureTarget.checkValidity();
    this.acceptButtonTarget.disabled = !enable;
  }
}
