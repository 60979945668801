import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"

// Connects to data-controller="notifications"
export default class extends Controller {
  static targets = ['notification', 'urgentDisplay', 'unseenIndicator'];

  connect() {
    this.#toggleUnseenIndicator();
    if(this.hasUrgentDisplayTarget) {
      let targetElement = this.urgentDisplayTarget; // use the first one if there are multiple
      targetElement.addEventListener('hidden.bs.modal', (event) => {
        this.#markSeen(event.target.dataset.id);
        event.target.remove();
      });
      document.querySelector('body').appendChild(targetElement); // Move this to the body so it displays..
      new bootstrap.Modal(targetElement, { backdrop: 'static', keyboard: false }).show();
    }
  }
  
  handleClick(event) {
    this.#markSeen(event.params.id);
  }

  #markSeen(id) {
    let updateUri = `/notifications/${id}.json`;
    let params = {
      notification: {
        seen_at: new Date().toISOString()
      }
    };
    let headers = {
      'Content-Type': 'application/json',
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
    };

    // Send Update:
    fetch(updateUri, { method: 'PUT', headers: headers, body: JSON.stringify(params) })
      .then(response => response.json())
      .then(responseData => {
        document.querySelector(`[data-notifications-id-param="${responseData.id}"]`)?.classList?.remove('unseen');
        delete document.querySelector(`[data-notifications-id-param="${responseData.id}"]`).dataset.action;
        this.#toggleUnseenIndicator();
      });
  }

  #toggleUnseenIndicator() {
    if(this.element.querySelectorAll('article.unseen').length > 0) {
      this.unseenIndicatorTarget.classList.remove('d-none');
    } else {
      this.unseenIndicatorTarget.classList.add('d-none');
    }
  }
}
