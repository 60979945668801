import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="line-item-variant"
export default class extends Controller {
  static targets = [
    "fixedRateSection",
    "percentToGenerateRateSection",
    "fixedRate",
    "percentToGenerateRate",
    "rateInputType",
    "internalCost",
    "minRate",
    "maxRate"
  ];
  static values = {
    additionalCosts: {
      type: Number,
      default: 0
    },
    minCostPercent: {
      type: Number,
      default: 0
    },
    maxCostPercent: {
      type: Number,
      default: 0
    },
    margin: {
      type: Number,
      default: 0
    }
  }

  connect() {
    if(this.marginValue > 0 && this.fixedRateTarget.value == '') {
      // Switch to the percent view:
      this.percentToGenerateRateTarget.value = this.marginValue.toString();
      this.rateInputTypeTarget.value = "%";
      this.updateFormFields();
    }
  }

  updateFormFields() {
    if(this.rateInputTypeTarget.value == "$") {
      this.fixedRateSectionTarget.classList.remove('d-none');
      this.percentToGenerateRateSectionTarget.classList.add('d-none');
    } else {
      this.percentToGenerateRateSectionTarget.classList.remove('d-none');
      this.fixedRateSectionTarget.classList.add('d-none');
      if(this.internalCostTarget.value > 0 && this.percentToGenerateRateTarget.value > 0) {
        const totalCosts = parseInt(this.additionalCostsValue) + parseInt(this.internalCostTarget.value);
        const percentToGenerateRate = parseInt(this.percentToGenerateRateTarget.value);
        const decimalToGenerateRate = percentToGenerateRate / 100;
        const newRate = this.#roundToTwo(totalCosts / (1 - decimalToGenerateRate)).toString();
        this.fixedRateTarget.value = newRate;
        this.percentToGenerateRateSectionTarget.querySelector('.afterthought').innerHTML = this.percentToGenerateRateSectionTarget.querySelector('.afterthought').innerHTML.replace(/[\d\.]+/, newRate.toString());
        this.#updateMinMax();
      }
    }
  }

  #updateMinMax() {
    const rateValue = parseInt(this.fixedRateTarget.value);
    if(rateValue > 0) {
      this.minRateTarget.value = this.#roundToTwo(rateValue * (this.minCostPercentValue/100));
      this.maxRateTarget.value = this.#roundToTwo(rateValue * (this.maxCostPercentValue/100));
    }
  }

  #roundToTwo(number) {
    return Math.round(100 * number) / 100;
  }
}
