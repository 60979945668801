import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="clipboard"
export default class extends Controller {
  connect() {
    
  }
  copy() {
    this.dispatch("copy"); //, { detail: { content: this.sourceTarget.value } })
    //navigator.clipboard.writeText(this.sourceTarget.value)
  }
}
