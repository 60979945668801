import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="tab-select"
export default class extends Controller {
  static targets = ['tabs', 'selector'];

  #setVisibleById(id) {
    if(id == '') { id = 'NEW_RECORD'; }
    this.tabsTarget.querySelectorAll('.tab-select-tab').forEach(element => element.classList.add('d-none'));
    this.tabsTarget.querySelectorAll(`.tab-select-tab[data-tab-select-id="${id}"]`).forEach(element => element.classList.remove('d-none'));
  }

  connect() {
    console.log('Tab Select Init');
    this.#setVisibleById(this.selectorTarget.value);
  }

  change(event) {
    this.#setVisibleById(event.target.value);
    this.element.closest('form').reset();
  }
}
