import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="toggle"
export default class extends Controller {
  connect() {
  }

  open(event, exclusive=true) {
    let targetElement = this.element.querySelector(event.params.target);
    if (exclusive && targetElement.dataset.toggleSetParam) {
      document.querySelectorAll(`[data-toggle-set-param="${targetElement.dataset.toggleSetParam}"]`).forEach(el => el.classList.add('d-none'));
    }
    targetElement.classList.remove('d-none');
  }
}
