import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"

// TODO: i18n

// Connects to data-controller="pdf--generate-and-download"
export default class extends Controller {
  #modalDialog(uri) {
    const id = self.crypto.randomUUID();
    let modalNode = document.createElement('div');
    modalNode.id = id
    modalNode.classList.add('modal', 'fade');
    modalNode.setAttribute('aria-hidden', 'true');
    modalNode.setAttribute('aria-labelledby', `${id}_TITLE`);
    modalNode.setAttribute('tabindex', '-1');
    modalNode.innerHTML = `
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="${id}_TITLE">Loading your PDF File</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="d-flex justify-content-center loading">
              <div class="spinner-grow mx-3" style="width: 3rem; height: 3rem;" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <div class="spinner-grow mx-3" style="width: 3rem; height: 3rem;" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
              <div class="spinner-grow mx-3" style="width: 3rem; height: 3rem;" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
            <div class="d-flex justify-content-center d-none link">
              <a href="${uri}" target="_blank" class="btn btn-success btn-lg">Download PDF</a>
            </div>
          </div>
        </div>
      </div>
    `;
    return new bootstrap.Modal(modalNode);
  }

  display(event) {
    event.preventDefault();
    const interval = 2500;                                                  // Retry interval, in ms
    const ref = this;                                                       // Hack, this passes the reference into the loop...

    let bsModal = this.#modalDialog(this.element.getAttribute('href'));     // The modal...
    let runLoop = true;                                                     // Used to allow closure of the dialog to cancel the loop.
    
    bsModal._element.addEventListener('hide.bs.modal', () => {
      console.log('...Dialog closed');
      runLoop = false;
      bsModal._element.remove();
    });
    event.target.closest('body').append(bsModal._element);
    bsModal.show();
    
    (function loop() {
      if(runLoop) {
        ref.#checkPdf(ref.element.getAttribute('href')).then((res) => {
          console.log(`Inside then: ${res}`);
          if(res) {
            // PDF ready for redirect:
            console.log('...Loop Complete');
            bsModal._element.querySelector('.modal-body').querySelectorAll('div').forEach((sec) => sec.classList.toggle('d-none'));
            runLoop = false;
          } else {
            // PDF not ready yet:
            console.log(`...Trying again in ${interval / 1000} seconds`);
            setTimeout(loop, interval);
          }
        }).catch((error) => {
          // Ugh...found an error:
          console.log(`Found error: ${error}`);
          bsModal.hide();
          const bsErrorModal = ref.#modalErrorDialog(error);
          document.querySelector('body').append(bsErrorModal._element);
          bsErrorModal.show();
          runLoop = false;
        });
      }
    })();
  }

  #modalErrorDialog(error) {
    const errorModalid = self.crypto.randomUUID();
    let errorModalElement = document.createElement('div');
    errorModalElement.id = errorModalid;
    errorModalElement.classList.add('modal', 'fade');
    errorModalElement.setAttribute('aria-hidden', 'true');
    errorModalElement.setAttribute('aria-labelledby', `${errorModalid}_TITLE`);
    errorModalElement.setAttribute('tabindex', '-1');
    errorModalElement.innerHTML = `
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header bg-danger text-light">
            <h1 class="modal-title fs-5" id="${errorModalid}_TITLE">Error!</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <p>${error}. For more info, please contact support.</p>
          </div>
        </div>
      </div>
    `;
    return new bootstrap.Modal(errorModalElement);
  }

  // Returns: true if the pdf is ready or an error is found, false if not.
  async #checkPdf(uri) {
    console.log(`Checking: ${uri}`);
    const response = await fetch(uri, { redirect: 'manual' });
    switch (response.status) {
      case 0:
        // the server redirected, meaning the resource here is ready:
        console.log(' --- Redirect - Good to go! - gonna return true');
        return true;
      case 202:
        // The file is not ready yet, keep goin:
        console.log(' --- 202 - Not ready yet, keep trying - gonna return false');
        return false;
      default:
        console.log(` --- ${response.status} - Not sure what to do here, gonna throw an error`);
        throw new Error(`Server returned status ${response.status}`);
    }
    /*
    fetch(uri, {redirect: 'manual'}).then(async (response) => {
      switch (response.status) {
        case 0:
          // the server redirected, meaning the resource here is ready:
          console.log(' --- Redirect - Good to go!');
          //modalDialog.hide();
          window.open(uri, '_blank');
          break;
        default:
          console.log(` --- ${response.status} - Don't know how to handle this :(`);
          
          const bsErrorModal = new bootstrap.Modal(errorModalElement);
          ref.element.closest('body').append(errorModalElement);
          bsErrorModal.show();
          break;
      }
    });
    */
    //console.log('should check pdf');
    //console.log(`HREF: ${ref.element.getAttribute('href')}`);
  }
}
