import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="new-li-form"
export default class extends Controller {
  static targets = [ 'minRate', 'maxRate', 'rate', 'cost', 'percentSection', 'fixedSection', 'sectionToggle' ];
  static values = {
    minPer: Number,
    maxPer: Number,
    margin: Number
  }

  connect() {
    this.toggleSections();
    console.log("I'm here 1")
  }
  
  updateRate() {
    console.log("I'm here 2")
    let costValue = Number(this.costTarget.value);
    if (costValue > 0) {
      if (this.marginValue > 0) {
        let newRate = costValue / (1 - this.marginValue);
        newRate = Math.round(newRate * 100) / 100;
        this.rateTarget.value = newRate;
        this.updateMax();
      }
    }
  }

  updateMax() {
    let rateValue = Number(this.rateTarget.value);
    if(rateValue > 0) {
      if (this.maxPerValue > 0) {
        let newMaxRate = rateValue * this.maxPerValue;
        newMaxRate = Math.round(newMaxRate * 100) / 100;
        this.maxRateTarget.value = newMaxRate;
      }
      if (this.minPerValue > 0) {
        let newMinRate = rateValue * this.minPerValue;
        newMinRate = Math.round(newMinRate * 100) / 100;
        this.minRateTarget.value = newMinRate;
      }
    }
  }

  toggleSections() {
    if (this.sectionToggleTarget.value == 'fixed') {
      this.percentSectionTarget.classList.add('d-none');
      this.fixedSectionTarget.classList.remove('d-none');
    } else {
      this.percentSectionTarget.classList.remove('d-none');
      this.fixedSectionTarget.classList.add('d-none');
    }
  }
}
