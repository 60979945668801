import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="estimates--options--line-items"
export default class extends Controller {
  #row;

  connect() {
  }

  dragStart(event) {
    this.#row = event.target;
  }

  dragOver(event) {
    event.preventDefault();

    let children = Array.from(event.target.parentNode.parentNode.children);

    if (children.indexOf(event.target.parentNode) > children.indexOf(this.#row))
      event.target.parentNode.after(this.#row);
    else
      event.target.parentNode.before(this.#row);
  }

  dragEnd(event) {
    const newIndex = Array.from(event.target.parentNode.children).indexOf(event.target) + 1;
    const originalPosition = parseInt(event.target.dataset.position);
    const uri = event.target.dataset.updateUri;
    if (newIndex != originalPosition) {
      fetch(uri, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'text/html',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
          'X-Requested-With': 'XMLHttpRequest'
        },
        body: JSON.stringify({
          estimate_line_item: {
            position: newIndex
          }
        })
      })
        .then((response) => {
          return response.text();
        })
        .then((partialHtml) => {
          this.element.innerHTML = partialHtml;
        })
        .catch((error) => {
          console.log("Something went wrong!", error);
        });
    } else {
      console.log(`drag-drop did not result in position change...`);
    }
  }
}
