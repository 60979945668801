import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="graphs--pie"
export default class extends Controller {
  static values = {
    graphData: Array,
    options: Object
  }

  #isLight(color) {
    let red = parseInt(color.substring(1, 3));
    let green = parseInt(color.substring(3, 5));
    let blue = parseInt(color.substring(5, 7));
    let sum = red + green + blue;
    return sum >= 384;
  }

  connect() {
    const width = this.element.offsetWidth;
    const height = this.element.offsetHeight;
    const radius = Math.min(width, height) / 2;
    const donutWidth = this.optionsValue.donutWidth; //This is the width of the donut bar
    console.log(donutWidth);
    const showLegend = this.optionsValue.showLegend;
    const color = d3.scaleOrdinal(d3.schemePaired);

    // Base Donut
    var svg = d3.select(this.element)
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', 'translate(' + (width / 2) + ',' + (height / 2) + ')')
      .attr('class', 'shadow');
    var arc = d3.arc()
      .outerRadius(radius);
    if (donutWidth > 0) {
      arc = arc.innerRadius(radius - donutWidth);
    } else {
      arc = arc.innerRadius(0);
    }
    var pie = d3.pie()
      .value(function (d) {
        return d.value;
      });
    // mouseover
    var tooltipDiv = d3.select(this.element).append("div")
      .attr("class", "tooltip-donut")
      .style("opacity", 0)
      .style("position", 'fixed');
    const path = svg.selectAll('path')
      .data(pie(this.graphDataValue))
      .enter()
      .append('path')
      .attr('d', arc)
      .attr('fill', function (d, i) {
        return color(d.data.title);
      })
      .attr('data-mouse', dta => {
        var output = dta.data.amount_label;
        if (dta.data.total != null && dta.data.total > 0) {         
          output += ` <br />${Math.round((dta.data.value / dta.data.total) * 100)}%`;
        }

        return output.toString();
        //return JSON.stringify(dta.data.amount_label);
      })
      .attr('transform', 'translate(0, 0)')
      .on('mouseenter', function (d, i) {
        d3.select(this).transition()
          .duration('50')
          .attr('opacity', '.55');
        tooltipDiv.transition()
          .duration(50)
          .style("opacity", 1);
        //let num = (Math.round((d.value / d.data.all) * 100)).toString() + '%';
        tooltipDiv.style("left", d.clientX + 10 + "px")
          .style("top", d.clientY - 15 + "px")
          .style('opacity', '1')
          .html(this.dataset.mouse);
      })
      .on('mouseout', function (d, i) {
        d3.select(this).transition()
          .duration('50')
          .attr('opacity', '1');
        tooltipDiv.transition()
          .duration('50')
          .style("opacity", 0);
      });
    
    if(showLegend) {
      let legendDiv = d3.select(this.element).append("ul").attr("class", "legend");
      let li = legendDiv.selectAll('.legend-entry').data(color.domain()).enter().append('li');
      li.text(function (d) {
        return `${d}`;
      });
      li.style('background', color);
      li.style('color', this.#isLight(color().toString()) ? 'black' : 'white');
      /*
      // Legend:
      var legendRectSize = 13;
      var legendSpacing = 7;
      var legend = svg.selectAll('.legend') //the legend and placement
        .data(color.domain())
        .enter()
        .append('g')
        .attr('class', 'circle-legend')
        .attr('transform', function (d, i) {
          var height = legendRectSize + legendSpacing;
          var offset = height * color.domain().length / 2;
          var horz = -2 * legendRectSize - 55;
          var vert = i * height - offset;
          return 'translate(' + horz + ',' + vert + ')';
        });
      legend.append('circle') //keys
        .style('fill', color)
        .style('stroke', color)
        .attr('cx', 0)
        .attr('cy', 0)
        .attr('r', '.5rem');
      legend.append('text') //labels
        .attr('x', legendRectSize + legendSpacing)
        .attr('y', legendRectSize - legendSpacing)
        .text(function (d) {
          return `${d}`;
        });
        */
    }
  }
}
