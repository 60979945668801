import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="users--select-multiple"
export default class extends Controller {
  static targets = [
    'allTechs',
    'leadTechSection',
    'leadUserIds',
    'selectTechDropdown',
    'selectedTechSection',
    'suggestedTechSection'
  ];

  connect() {
    this.updateSelectedTechs();
    
    // Subscribe to the local form's submit event to handle submission of this field:
    this.element.closest('form').addEventListener('submit', () => {
      this.handleLeadTechs();
    });

    // Add stars to lead techs, since css doesn't want to do it:
    //let peopleNode = document.createElement('i');
    //peopleNode.classList.add('bi');
    //peopleNode.classList.add('bi-people-fill');
    this.selectTechDropdownTarget.querySelectorAll('option.lead-user').forEach(leadTechOption => {
      leadTechOption.innerHTML = `&#9733;&nbsp;${leadTechOption.text}`;
    });
  }
  
  log() {
    console.log('I am here');
  }

  addUser() {
    const idToSelect = this.selectTechDropdownTarget.value;
    if(idToSelect != null && idToSelect != '') {
      let option = this.allTechsTarget.querySelector(`option[value='${idToSelect}']`);

      option.selected = true;
      this.selectTechDropdownTarget.value = '';
      this.updateSelectedTechs();
    }
  }

  addSuggestedTech(event) {
    let idToAdd = event.params.userIdToAdd;
    let option = this.allTechsTarget.querySelector(`option[value='${idToAdd}']`);
    option.selected = true;
    if(this.suggestedTechSectionTarget.querySelectorAll('li').length == 0) {
      this.suggestedTechSectionTarget.classList.add('d-none');
    }

    this.updateSelectedTechs();

    // Remove any suggestions with this user id (the user might be suggested in multiple categories):
    document.querySelectorAll(`[data-users--select-multiple-user-id-to-add-param="${idToAdd}"]`).forEach((linkToRemove) => {
      let liToRemove = linkToRemove.closest('li');
      liToRemove.remove();
    });
  }

  removeUser(event) {
    const idToRemove = event.params.userId;
    if (this.allTechsTarget.querySelector(`option[value='${idToRemove}']`).dataset.isLead) {
      delete this.allTechsTarget.querySelector(`option[value='${idToRemove}']`).dataset.isLead;
    }
    this.allTechsTarget.querySelector(`option[value='${idToRemove}']`).selected = false;
    event.target.closest('li').remove();
    if(this.leadTechSectionTarget.querySelector('ul').children.length == 0) {
      this.leadTechSectionTarget.classList.add('d-none');
    }
    this.updateSelectedTechs();
  }

  toggleLead(event) {
    let leadLink = event.target.closest('a');
    let leadLi = leadLink.closest('li');
    let leadOption = this.allTechsTarget.querySelector(`option[value='${event.target.closest('li').querySelector('[data-action="click->users--select-multiple#toggleLead:prevent"]').getAttribute('data-users--select-multiple-user-id-param')}']`)
    let currentState = leadLink.dataset.isLead;

    if(currentState == 'true') {
      // Move to normal section:
      this.selectedTechSectionTarget.querySelector('ul').appendChild(leadLi);
      if(this.leadTechSectionTarget.querySelector('ul').children.length == 0) {
        this.leadTechSectionTarget.classList.add('d-none');
      }

      leadLink.innerHTML = 'Make Lead';
      delete leadOption.dataset.isLead;
      leadLink.dataset.isLead = ''
    } else {
      // Move to lead section:
      this.leadTechSectionTarget.classList.remove('d-none');
      this.leadTechSectionTarget.querySelector('ul').appendChild(leadLi);

      leadLink.innerHTML = 'Remove Lead';
      leadOption.dataset.isLead = true;
      leadLink.dataset.isLead = 'true';
    }
  }

  updateSelectedTechs() {
    let selectedOptions = Array.from(this.allTechsTarget.selectedOptions);
    let assistantTechsUlElement = this.selectedTechSectionTarget.querySelector('ul');
    let leadTechsUlElement = this.leadTechSectionTarget.querySelector('ul');

    // Empty the Selected Techs:
    assistantTechsUlElement.innerHTML = '';
    leadTechsUlElement.innerHTML = '';
    // TODO: i18n
    this.selectTechDropdownTarget.setCustomValidity('Please add at least one tech');

    selectedOptions.forEach(selectedTechOption => {
      if(selectedTechOption.dataset.isLead) {
        leadTechsUlElement.appendChild(this.#liFor(selectedTechOption));
      } else {
        assistantTechsUlElement.appendChild(this.#liFor(selectedTechOption));
      }
    });

    // Remove validation if a tech is selected (lead or not):
    if (selectedOptions.length > 0) {
      this.selectTechDropdownTarget.setCustomValidity('');
    }

    // Toggle section visibility:
    if (leadTechsUlElement.children.length > 0) {
      this.leadTechSectionTarget.classList.remove('d-none');
    } else {
      this.leadTechSectionTarget.classList.add('d-none');
    }
    if (assistantTechsUlElement.children.length > 0) {
      this.selectedTechSectionTarget.classList.remove('d-none');
    } else {
      this.selectedTechSectionTarget.classList.add('d-none');
    }
  }

  getSuggestedTechs(addressId, visitType, startTime=null) {
    console.log('Should Suggest Techs')
    this.suggestedTechSectionTarget.querySelector('ul').innerHTML = '';

    if (visitType != "" && visitType != null && addressId != "" && addressId != null) {
      let techsUri = `/users/suggestions?selected_event_category_id=${visitType}&selected_address_id=${addressId}&start_time=${startTime}`;
      fetch(techsUri)
        .then((response) => response.text())
        .then((data) => {
          this.suggestedTechSectionTarget.classList.remove('d-none');
          this.suggestedTechSectionTarget.querySelector('ul').innerHTML = data;
        });
    }
  }

  handleLeadTechs() {
    let selectedOptions = Array.from(this.allTechsTarget.selectedOptions);
    selectedOptions.forEach(e => {
      if(e.dataset.isLead) {
        let newNode = e.cloneNode(true);
        newNode.selected = true;
        this.leadUserIdsTarget.appendChild(newNode);
      }
    });
  }

  #liFor(option) {
    liElement = document.createElement('li');
    // TODO: i18n
    liElement.innerHTML = `
      ${option.text} (<a href="#" data-action="click->users--select-multiple#removeUser:prevent" data-users--select-multiple-user-id-param="${option.value}"><i class="bi bi-x text-danger"></i></a>
      <a href="#" data-action="click->users--select-multiple#toggleLead:prevent" ${option.dataset.isLead ? 'data-is-lead="true"' : '' } data-users--select-multiple-user-id-param="${option.value}">${option.dataset.isLead ? 'Remove' : 'Make'} Lead</a>)
    `.replace(/\s+/mg, ' ');
    return liElement;
  }
}
