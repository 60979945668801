import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="gcs--maps"
export default class extends Controller {
  static values = {
    address: String,
    gcsKey: String
  }

  connect() {
    this.element.setAttribute('src', `https://maps.googleapis.com/maps/api/staticmap?markers=|${this.addressValue}&zoom=10&key=${this.gcsKeyValue}&size=100x100`);
  }
}
