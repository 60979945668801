import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="barsinglechart"
export default class extends Controller {
  static values = {
    totalWon: Number,
    totalGoal: Number,
    showLabel: Boolean
  }

  connect() {
    const data1 = {
        inactiveColor: "#05476E1F",
        activeColor: "#05476E",
        data: [
            { group: "Sun", value: 6 },
            { group: "Mon", value: 10 },
            { group: "Tue", value: 8 },
            { group: "Wed", value: 9 },
            { group: "Thu", value: 15 },
            { group: "Fri", value: 6 },
            { group: "Sat", value: 8 }
        ]
    };
    
    (() => {
        const el = d3.select(this.element);
        const margin = 10;
        // const margin = el.node().offsetWidth / 10;
        const width = el.node().offsetWidth;
        const height = el.node().offsetHeight ? el.node().offsetHeight - margin * 2 : width * 0.45;
    
        const tooltip = el
            .append("div")
            .style("position", "relative")
            .style("width", "100%")
            .append("div")
            .style("position", "absolute")
            .style("opacity", 0)
            .attr("class", "tooltip")
            .style("background-color", "white")
            .style("border", "solid")
            .style("border-width", "1px")
            .style("border-radius", "5px")
            .style("padding", "10px");
    
        const svg = el
            .append("svg")
            .attr("viewBox", `0 0 ${width} ${height + margin * 2}`)
            .append("g")
            .attr("transform", `translate(0,${margin / 2})`);
    
        const x = d3.scaleBand()
            .range([0, width])
            // .padding(0.4);
        const xAxis = svg.append("g")
            .attr("transform", `translate(0,${height})`)
        xAxis.attr('stroke-width', 0)
    
        const y = d3.scaleLinear()
            .range([height, 0]);
        const yAxis = svg.append("g")
            .attr("class", "myYaxis")
            .remove()
    
        function update(param) {
            const data = param.data
    
            x.domain(data.map(d => d.group))
            xAxis.call(d3.axisBottom(x).tickFormat(d => d.substring(0, 1)))
    
            y.domain([0, d3.max(data, d => d.value)]);
            yAxis.transition().duration(1000).call(d3.axisLeft(y));
    
            const bar = svg.selectAll("rect")
                .data(data)
    
            bar
                .join("rect")
                .attr("x", d => x(d.group) + (x.bandwidth()-28)/2)
                .attr("y", d => y(d.value))
                .attr("width", 28)
                .attr("height", d => height - y(d.value))
                .attr("fill", param.inactiveColor)
                .attr('rx', 5)
                .on("mouseover", function (d) {
                    d3.select(this).style("fill", param.activeColor);
                    tooltip.text(d.target.__data__.value)
                    .style("opacity", 1);
                })
                .on("mousemove", (d) => {
                    tooltip.style("transform", `translate(20%, -100%)`)
                        .style("left", (d.offsetX) + "px")
                        .style("top", (d.offsetY) + "px")
                })
                .on("mouseout", function (d) {
                    d3.select(this).style("fill", param.inactiveColor);
                    tooltip.style('opacity', 0)
                });
        }
        update(data1)
    })();
  }
}
