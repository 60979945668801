import { Controller } from "@hotwired/stimulus"


// Connects to data-controller="fill-variables"
export default class extends Controller {
  static targets = ['textArea']
  connect() {
  }
    
  
  insert(event) {
   
    console.log(event.target.value);
    let textArea = this.textAreaTarget;
    let copy = textArea.value
    const textNode = document.createTextNode(textArea.value);
    textArea.addEventListener('click', () => {
       let position = this.getCaretPosition(textArea, textNode);
    
      if (position.start !== null && position.end !== null) {
        if (position.start === position.end) {
          console.log("same number");
          let charArray = copy.split('');
          charArray.splice(position.start,0, event.target.value);
          let newValue = charArray.join('');
          textArea.value = newValue
        } else {
          let charArray = copy.split('');
          let displacement = position.end - position.start
          charArray.splice(position.start, displacement , event.target.value);
          let newValue = charArray.join('');
          textArea.value = newValue
          console.log("different numbers");
        }
      }
      event.target.value = '';
    });
  }

  getCaretPosition(textArea, textNode) {
    const { selectionStart, selectionEnd } = textArea;
    if (selectionStart === null || selectionEnd === null) {
      return null; // No selection
    }

    const range = document.createRange();
    range.setStart(textNode, selectionStart);
    range.setEnd(textNode, selectionEnd);
    let end= range.endOffset
    let start = range.startOffset;
    let position = {start: start, end: end};
    return position
  }


}
