import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="speedometer"
export default class extends Controller {
  static values = {
    totalWon: Number,
    totalGoal: Number,
    chartForGoal: String
  }

  connect() {
    var value = this.totalWonValue;
    var gaugeMaxValue = this.totalGoalValue;
    var chartForGoal = this.chartForGoalValue

    var percentValue = Math.min(1, value / gaugeMaxValue);
    var needle;

    (() => {
      var barWidth, chart, chartInset, degToRad, repaintGauge,
        height, margin, padRad, percToDeg, percToRad,
        percent, radius, svg, totalPercent, width, arc1, arc2, arc3;
      percent = percentValue;
      padRad = 0.025;
      chartInset = 10;

      // Orientation of gauge:
      totalPercent = 0.625;
      var el = d3.select(this.element);
      margin = {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      };

      // If full-height, prioritize height on sizing:
      if (this.element.classList.contains('h-100')) {
        height = this.element.parentElement.offsetHeight - margin.top - margin.bottom;
        width = height;
      } else {
        width = this.element.offsetWidth - margin.left - margin.right;
        height = width;
      }
      radius = Math.min(width, height) / 2;
      barWidth = 35 * width / 300;


      //Utility methods 
      percToDeg = function (perc) {
        return perc * 360;
      };

      percToRad = function (perc) {
        return degToRad(percToDeg(perc));
      };

      degToRad = function (deg) {
        return deg * Math.PI / 180;
      };

      // Create SVG element
      svg = el.append('svg').attr('width', width + margin.left + margin.right).attr('height', height + margin.top + margin.bottom);

      // Add layer for the panel
      chart = svg.append('g').attr('transform', "translate(" + ((width + margin.left) / 2) + ", " + ((height + margin.top) / 2) + ")");
      if (chartForGoal == "true" || chartForGoal == ""){
        chart.append('path').attr('class', "arc chart-first-forwards");
        chart.append('path').attr('class', "arc chart-second-forwards");
        chart.append('path').attr('class', "arc chart-third-forwards");
      }else{
        chart.append('path').attr('class', "arc chart-first-backwards");
        chart.append('path').attr('class', "arc chart-second-backwards");
        chart.append('path').attr('class', "arc chart-third-backwards");
      }
      arc3 = d3.arc().outerRadius(radius - chartInset).innerRadius(radius - chartInset - barWidth - 10 * width / 300).cornerRadius(50);
      arc2 = d3.arc().outerRadius(radius - chartInset).innerRadius(radius - chartInset - barWidth - 3 * width / 300).cornerRadius(50);
      arc1 = d3.arc().outerRadius(radius - chartInset).innerRadius(radius - chartInset - barWidth).cornerRadius(30);


      repaintGauge = function () {
        var perc = 0.75; //0.5
        var next_start = totalPercent;
        var arcStartRad, arcEndRad;
        arcStartRad = percToRad(next_start);
        arcEndRad = arcStartRad + percToRad(perc / 2);
        next_start += perc / 2.5;
        arc1.startAngle(arcStartRad).endAngle(arcEndRad);

        arcStartRad = percToRad(next_start);
        arcEndRad = arcStartRad + percToRad(perc / 3 - 0.007);
        next_start += (perc / 3 - 0.03);
        arc2.startAngle(arcStartRad).endAngle(arcEndRad);

        arcStartRad = percToRad(next_start);
        arcEndRad = arcStartRad + percToRad(perc / 3);
        arc3.startAngle(arcStartRad).endAngle(arcEndRad);

        chart.select(".chart-first-forwards").attr('d', arc1).attr("fill", "#F7685B");
        chart.select(".chart-second-forwards").attr('d', arc2).attr("fill", "#FFD66B");
        chart.select(".chart-third-forwards").attr('d', arc3).attr("fill", "#2ED47A");
        
        chart.select(".chart-third-backwards").attr('d', arc3).attr("fill", "#F7685B");
        chart.select(".chart-second-backwards").attr('d', arc2).attr("fill", "#FFD66B");
        chart.select(".chart-first-backwards").attr('d', arc1).attr("fill", "#2ED47A");
      }

      var Needle = (function () {
        var recalcPointerPos = function (perc) {
          var centerX, centerY, leftX, leftY, rightX, rightY, thetaRad, topX, topY;
          var deg = percToDeg(perc * 240 / 360) - 30;
          thetaRad = degToRad(deg);
          centerX = 0;
          centerY = 50 * width / 400;
          topX = centerX - this.len * Math.cos(thetaRad);
          topY = centerY - this.len * Math.sin(thetaRad);
          leftX = centerX - this.radius * Math.cos(thetaRad - Math.PI / 2);
          leftY = centerY - this.radius * Math.sin(thetaRad - Math.PI / 2);
          rightX = centerX - this.radius * Math.cos(thetaRad + Math.PI / 2);
          rightY = centerY - this.radius * Math.sin(thetaRad + Math.PI / 2);
          return "M " + leftX + " " + leftY + " L " + topX + " " + topY + " L " + rightX + " " + rightY;
        };

        function Needle(el) {
          this.el = el;
          this.len = width / 2.75;
          this.radius = this.len / 7;
        }

        Needle.prototype.render = function () {
          this.el.append('circle').attr('class', 'needle-center').attr('cx', 0).attr('cy', 50 * width / 400).attr('r', this.radius).attr("fill", "#000000");
          this.el.append('path').attr('class', 'needle').attr('id', 'client-needle').attr('d', recalcPointerPos.call(this, 0));
          return this.el.append('circle').attr('class', 'needle-center-center').attr('cx', 0).attr('cy', 50 * width / 400).attr('r', this.radius/1.75).attr("fill", "#FFFFFF");
        };

        Needle.prototype.moveTo = function (perc) {
          var self,
            oldValue = this.perc || 0;

          this.perc = perc;
          if(isNaN(perc)){
            perc = 0;
          }
          self = this;

          // Reset pointer position
          this.el.transition().delay(100).duration(200).select('.needle').tween('reset-progress', function () {
            return function (percentOfPercent) {
              var progress = (1 - percentOfPercent) * oldValue;
              repaintGauge(progress);
              return d3.select(this).attr('d', recalcPointerPos.call(self, progress));
            };
          });

          this.el.transition().delay(300).duration(1500).select('.needle').tween('progress', function () {
            return function (percentOfPercent) {
              var progress = percentOfPercent * perc;
              repaintGauge(progress);
              return d3.select(this).attr('d', recalcPointerPos.call(self, progress));
            };
          });
        };
        return Needle;
      })();

      needle = new Needle(chart);
      needle.render();
      needle.moveTo(percent);
    })();
  }
}
