import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="line-items--uploads"
export default class extends Controller {
  static targets = [ "rows", "rowsInModal", 'rowsInShow' ];
  connect() {
    console.log('connect!');
  }

  toggleChecked(event) {
    let rowNumber = event.params.rowNumber;
    let row = event.target.closest('tr');
    if(event.target.checked) {
      this.rowsInModalTargets.forEach(target => {
        let newRow = row.cloneNode(true);
        newRow.dataset.rowId = `MODAL_ROW_NUMBER_${rowNumber}`
        target.appendChild(newRow);
      });
    } else {
      let newRows = document.querySelectorAll(`[data-row-id="MODAL_ROW_NUMBER_${rowNumber}"]`);
      let showBox = document.getElementById(`SHOW_MODAL_BOX_${rowNumber}`);

      newRows.forEach(r => r.remove());
      showBox.checked = false;
    }
  }
}
