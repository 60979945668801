import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="splinechart"
export default class extends Controller {
  static values = {
    totalWon: Number,
    totalGoal: Number,
    showLabel: Boolean
  }

  connect() {
    const el = d3.select(this.element);
    const width = Math.max(600, el.node().offsetWidth);
    const height = el.node().offsetHeight;
    var input = [58, 20, 58, 45, 21, 68, 32, 45, 58, 62, 78, 95];
    var margin = { top: 0, bottom: 0, left: 0, right: 0 };
    var padding = { top: 0, bottom: 64, left: 0, right: 0 };
    var labelXPaddingBottom = 32;
    var labelXContent = { width: 30, height: 15 };
    var gridX = [0, 20, 40, 60, 80, 100];
    var intervalY = (height - padding.top - padding.bottom) / (gridX.length - 1);
    var data = [];
    var valueX = makeArr(padding.left + labelXContent.width, width - padding.right, input.length);
    var labelX = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    for (var index = 0; index < input.length; index++) {
        data.push({
            name: valueX[index],
            value: input[index]
        })
    }
    var scaleY = d3.scaleLinear()
        .domain([d3.min(input), d3.max(input)])
        .range([padding.bottom, height - padding.top]);

    var chart = el
        .append("svg")
        .attr("viewBox", `0 0 ${width} ${height}`)
        .style("min-width", width)

    chart.selectAll('line')
        .data(gridX)
        .enter()
        .append('line')
        .attr('x1', padding.left + labelXContent.width)
        .attr('x2', width - padding.right)
        .attr('y1', function (d, i) {
            return height - padding.bottom - intervalY * i;
        })
        .attr('y2', function (d, i) {
            return height - padding.bottom - intervalY * i;
        })
        .attr('stroke-linecap', 'round')
        .style('stroke', '#f5f7fb')
        .attr('stroke-width', 3);

    chart.selectAll('text.y')
        .data(gridX)
        .enter()
        .append("text")
        .style('font-size', '12px')
        .style('font-family', 'Poppins')
        .style('font-weight', '400')
        .attr('fill', '#707683')
        .attr("x", padding.left)
        .attr("y", padding.top + labelXContent.height / 2)
        .attr('dx', 0)
        .attr('dy', function (d, i) {
            return i * intervalY - labelXContent.height / 2;
        })
        .text(function (d, i) { return gridX[gridX.length - i - 1]; });

    chart.selectAll('text.x')
        .data(labelX)
        .enter()
        .append("text")
        .attr('fill', '#707683')
        .style('font-size', '12px')
        .style('font-family', 'Poppins')
        .style('font-weight', '400')
        .attr('width', (width - padding.left - padding.right - labelXContent.width) / (labelX.length - 1))
        .attr("x", padding.left + labelXContent.width)
        .attr("y", height - labelXPaddingBottom)
        .attr('dy', 0)
        .attr('dx', function (d, i) {
            return i * 0.97 * (width - padding.left - padding.right - labelXContent.width) / (labelX.length - 1);
        })
        .text(function (d, i) { return d });

    chart.append("linearGradient")
        .attr("id", "line-gradient")
        .attr("gradientUnits", "userSpaceOnUse")
        .attr("x1", 0).attr("y1", (0))
        .attr("x2", 1000).attr("y2", (0))
        .selectAll("stop")
        .data([
            { offset: "0%", color: '#05476E' },
            { offset: "100%", color: '#56CA00' }
        ])
        .enter().append("stop")
        .attr("offset", function (d) { return d.offset; })
        .attr("stop-color", function (d) { return d.color; });

    chart.append("path")
        .datum(data)
        .attr("fill", "none")
        .attr("stroke", "url(#line-gradient)")
        .attr("stroke-width", 5)
        .attr("d", d3.line()
            .curve(d3.curveMonotoneX)
            .x(function (d) { return d.name })
            .y(function (d) { return height - scaleY(d.value) }));

    chart.append("path")
        .datum(data)
        .attr("fill", "none")
        .attr("stroke", "url(#line-gradient)")
        .attr("stroke-width", 5)
        .attr("d", d3.line()
            .curve(d3.curveMonotoneX)
            .x(function (d) { return d.name })
            .y(function (d) { return height - scaleY(d.value) }));

    chart.selectAll("myCircles.out")
        .data(data)
        .enter()
        .append("circle")
        .attr("fill", "#AE8FF7")
        .attr("stroke", "none")
        .attr("cx", function (d) { return d.name })
        .attr("cy", function (d) { return height - scaleY(d.value) })
        .attr("r", function (d, i) {
            if (i == 0 || i == data.length - 1) return 0;
            else return 7;
        })

    chart.selectAll("myCircles.in")
        .data(data)
        .enter()
        .append("circle")
        .attr("fill", "#ffffff")
        .attr("stroke", "none")
        .attr("cx", function (d) { return d.name })
        .attr("cy", function (d) { return height - scaleY(d.value) })
        .attr("r", function (d, i) {
            if (i == 0 || i == data.length - 1) return 0;
            else return 4;
        })

    const tooltip_g = chart.append('g').style('display', 'none');
    tooltip_g.append('rect').attr('width', 90).attr('height', 56).attr('rx', 10).attr('stroke', '#0c0059').attr('fill', '#0c0059').style('display', 'none');
    tooltip_g.append('text').text('Sales').attr('x', 45).attr('y', 25).attr('fill', 'white').attr('text-anchor', 'middle').style('opacity', 0.8).style('font-size', '12px');
    tooltip_g.append('text').attr('id', 'tooltip-value').text(0).attr('x', 45).attr('text-anchor', 'middle').attr('y', 45).attr('fill', 'white').style('opacity', 1).style('font-size', '16px').style('position', 'absolute');
    tooltip_g.append("path")
        .attr('id', 'triangle')
        .attr("d", d3.symbol().type(d3.symbolTriangle).size(200))
        .attr("fill", "#0c0059")
        .attr("transform", "rotate(-180, 45,56) translate(45, 52)")
        .style('display', 'none');

    const mouse_g = chart.append('g').classed('mouse', true).style('display', 'none');
    mouse_g.append('line').attr('stroke', 'lightgrey').attr('stroke-width', 2).attr('stroke-dasharray', '5, 5').attr('stroke', '#AE8FF7');
    mouse_g.append('text');

    chart.on("mouseover", function (mouse) {
        mouse_g.style('display', 'block');
        tooltip_g.style('display', 'block');
    });

    const [min_year, max_year] = d3.extent(data, d => d.name);
    chart.on("mousemove", function (mouse) {
        const [x_cord, y_cord] = d3.pointer(mouse);
        if (x_cord - padding.left > 0) {

            const ratio = (x_cord - padding.left - labelXContent.width) / (width - padding.left - padding.right - labelXContent.width);
            const current_year = min_year + Math.round(ratio * (max_year - min_year));
            const cnt_array = data.find(item => Math.abs(item.name.toFixed() - current_year) < 2);

            if (cnt_array != null && cnt_array != undefined) {
                let cnt = cnt_array.value;

                mouse_g.select('line')
                    .attr('x1', cnt_array.name)
                    .attr('x2', cnt_array.name)
                    .attr('y1', height - scaleY(cnt))
                    .attr('y2', height - padding.bottom);

                tooltip_g.select('#tooltip-value')
                    .text(cnt.toString());
                tooltip_g.attr('transform', 'translate(' + (cnt_array.name - 90 / 2) + ', ' + (height - scaleY(cnt) - 56 - 20) + ')')
                    .style('display', 'block');
                tooltip_g.select('rect').style('display', 'block')
                tooltip_g.select('#triangle').style('display', 'block');

            }
        }
    });

    chart.on("mouseout", function (mouse) {
        mouse_g.style('display', 'none');
        tooltip_g.style('display', 'none');
        tooltip_g.select('triangle').style('display', 'none');
    });

    function makeArr(startValue, stopValue, cardinality) {
        var arr = [];
        var step = (stopValue - startValue) / (cardinality - 1);
        for (var i = 0; i < cardinality; i++) {
            arr.push(startValue + (step * i));
        }
        return arr;
    }
  }
}
