import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="invoices"
export default class extends Controller {
  static targets = [ 'percentageSection' ]

  togglePercentage(event) {
    if(event.target.checked) {
      this.percentageSectionTarget.classList.remove('d-none');
    } else {
      this.percentageSectionTarget.classList.add('d-none');
    }
  }
}
