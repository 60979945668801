import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"

// Connects to data-controller="new-customer"
export default class extends Controller {
  static targets = [ "modal", "firstName", "lastName", "phoneNumber", "companyName" ];

  #modal;

  connect() {
    this.#modal = new bootstrap.Modal(this.modalTarget);
    const urlParams = new URLSearchParams(window.location.search);
    if(urlParams.has('inbound')) {
      this.phoneNumberTarget.value = urlParams.get('inbound');
      if(urlParams.has('caller_id') && urlParams.get('caller_id').match(/[^A-Za-z\']/).length == 1)
      {
        this.firstNameTarget.value = urlParams.get('caller_id')?.match(/[A-Za-z]+[^A-Za-z]?/)[0];
        this.lastNameTarget.value = urlParams.get('caller_id')?.match(/[^A-Za-z].+/)[0]?.match(/[A-Za-z].+/)[0];
      } else {
        this.companyNameTarget.value = urlParams.get('caller_id');
      }

      this.#modal.show();
    }
  }
}
