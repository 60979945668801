import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="schedule--events"
export default class extends Controller {
  static targets = [
    'addressSelector',
    'contactSelector',
    'followupSection',
    'multiDaySection',
    'schedulableSelector',
    'sectionTwo',
    'sectionThree',
    'startTime',
    'visitTypeSelector'
  ];

  connect() {
  }

  selectCustomer(event) {
    let customerId = event.target.value;
    let addressUri = `/customers/${customerId}/addresses`;
    let contactsUri = `/customers/${customerId}/customer_contacts`;

    if(this.hasAddressSelectorTarget) {
      fetch(addressUri)
        .then((response) => response.text())
        .then((data) => {
          this.addressSelectorTarget.innerHTML = data;
          this.addressSelectorTarget.setAttribute('required', '');
        });
    }
    if(this.hasContactSelectorTarget) {
      fetch(contactsUri)
        .then((response) => response.text())
        .then((data) => {
          this.contactSelectorTarget.innerHTML = data;
        });
    }
    if(this.hasSectionTwoTarget) {
      this.sectionTwoTarget.classList.remove('d-none');
    }
  }

  selectAddress(event) {
    if (event.target.value == '') { return; }
    let schedulableUri = `/addresses/${event.target.value}/schedulables`;

    if(this.hasSectionThreeTarget) {
      this.sectionThreeTarget.classList.remove('d-none');
    }
    if(this.hasSchedulableSelectorTarget) {
      fetch(schedulableUri)
        .then((response) => response.text())
        .then((data) => {
          this.schedulableSelectorTarget.querySelector('select').innerHTML = data;
        });
    }
    if (this.hasFollowupSectionTarget) {
      const followupOptionsUri = `/addresses/${event.target.value}/schedule/events/get_followup_options`;
      fetch(followupOptionsUri)
        .then((response) => response.text())
        .then((data) => {
          this.followupSectionTarget.querySelector('select').innerHTML = data;
          this.followupSectionTarget.classList.remove('d-none');
        });
    }
  }

  toggleSchedulableSelector(event) {
    if(event.target.checked) {
      this.schedulableSelectorTarget.classList.add('d-none');
      this.schedulableSelectorTarget.querySelector('select').value = '';
    } else {
      this.schedulableSelectorTarget.classList.remove('d-none');
    }
  }

  toggleMultiDaySection(event) {
    if (event.target.checked) {
      this.multiDaySectionTarget.classList.remove('d-none');
    } else {
      this.multiDaySectionTarget.classList.add('d-none');
      this.multiDaySectionTarget.querySelector('input').value = '';
    }
  }

  toggleFollowupSection(event) {
    if (event.target.checked) {
      this.followupSectionTarget.querySelector('.details').classList.remove('d-none');
      this.followupSectionTarget.querySelector('select').setAttribute('required', true);
    } else {
      this.followupSectionTarget.querySelector('.details').classList.add('d-none');
      this.followupSectionTarget.querySelector('select').value = '';
      this.followupSectionTarget.querySelector('select').removeAttribute('required');
    }
  }

  triggerSuggestions() {
    this.element.querySelectorAll('[data-controller="users--select-multiple"]').forEach(userSelector => {
      this.application.getControllerForElementAndIdentifier(userSelector, 'users--select-multiple').getSuggestedTechs(this.addressSelectorTarget.value, this.visitTypeSelectorTarget.value, this.startTimeTarget.value);
    });
  }
}