import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"

// Connects to data-controller="ux3"
export default class extends Controller {
  static targets = [
    'exclusive',
    'filter',
    'modals'
  ];

  connect() {
    console.log('UX3 Loading...');
    this.element.querySelectorAll('.modal.move').forEach(el => {
      this.modalsTarget.appendChild(el);
    });

    this.#setupExclusiveCollapses();
    this.#showFiltersIfApplied();
  }

  #showFiltersIfApplied() {
    if (window.location.toString().includes('?')) {
      this.filterTargets.forEach(filterBox => {
        const bsItem = new bootstrap.Collapse(filterBox, { toggle: false });
        bsItem.show();
      });
    }
  }

  #setupExclusiveCollapses() {
    this.exclusiveTargets.forEach((node) => {
      node.addEventListener('show.bs.collapse', event => {
        this.exclusiveTargets.forEach(exclusiveNode => {
          if (!event.target.isSameNode(exclusiveNode)) {
            const bsItem = new bootstrap.Collapse(exclusiveNode, { toggle: false });
            bsItem.hide();
          }
        });
      });
    });
  }
}

/*
        if (!event.target.isSameNode(target)) {
          const bsItem = new bootstrap.Collapse(target, { toggle: false });
          bsItem.hide();
        }
        */