import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="new-line-item"
export default class extends Controller {
  static targets = [
    "categoryField",
    "descriptionField",
    "editSection",
    "modal",
    "nameField",
    "percentRateField",
    "quantityField",
    "rateField",
    "rateTypeField",
    "selectorModal"
  ];

  connect() {
  }

  updateForm(event) {
    console.log('should update form');
    let selectedData = JSON.parse(this.selectorModalTarget.dataset.selectedDetails);
    if(selectedData.type == 'LineItemVariant') {
      console.log(`Should set rate fields by rate type: ${selectedData.rate_type}`)
      if(selectedData.rate_type == 'fixed') {
        this.rateFieldTarget.closest('.col').classList.remove('d-none');
        this.rateFieldTarget.value = selectedData.rate;
        if(this.hasPercentRateFieldTarget) {
          this.percentRateFieldTarget.closest('.col').classList.add('d-none');
          this.percentRateFieldTarget.value = null;
        }
      } else {
        // ignore rates if percent rates are not supported:
        if(this.hasPercentRateFieldTarget) {
          this.rateFieldTarget.closest('.col').classList.add('d-none');
          this.percentRateFieldTarget.closest('.col').classList.remove('d-none');
          this.rateFieldTarget.value = null;
          this.percentRateFieldTarget.value = selectedData.rate;
        }
      }
      if(this.hasEditSectionTarget) {
        this.editSectionTarget.classList.remove('d-none');
      }
      //this.categoryFieldTarget.value = selectedData.parent_id;
      this.nameFieldTarget.value = selectedData.name;
      if(this.hasDescriptionFieldTarget) {
        this.descriptionFieldTarget.value = selectedData.description;
      }
      if(this.hasRateTypeField) {
        this.rateTypeFieldTarget.value = selectedData.rate_type;
      }
    }
  }
}
