import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="test"
export default class extends Controller {
  #row;

  connect() {
    alert('Hello World');
  }

  dragStart(event) {
    this.#row = event.target;
  }

  dragOver(event) {
    event.preventDefault();

    let children = Array.from(event.target.parentNode.parentNode.children);

    if (children.indexOf(event.target.parentNode) > children.indexOf(this.#row))
      event.target.parentNode.after(this.#row);
    else
      event.target.parentNode.before(this.#row);
  }

  dragEnd(event) {
    let newIndex = Array.from(event.target.parentNode.children).indexOf(event.target) + 1;
    if (newIndex != parseInt(event.target.dataset.position)) {
      alert(`should report new position: ${newIndex}`);
    } else {
      alert('Did not move');
    }
  }
}
