import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="schedule"
export default class extends Controller {
  static targets = ['hour', 'user', 'event', 'map'];
  static values = {
    hoursDivisions: Number,
    startHour: Number
  };

  #dragInfo = {
    dragElement: null,
    eventElement: null,
    mode: null
  };

  connect() {
  }

  toggleTechTypeSection(event) {
    this.element.querySelectorAll(`.user-row[data-in-group="${event.params.group}"]`).forEach(r => {
      r.classList.toggle('d-none');
    });
    event.target.querySelectorAll('.d-inline').forEach(r => {
      r.classList.toggle('d-none');
    });
  }

  dragStart(event) {
    // Get the grid header info:
    this.#dragInfo.dragElement = event.target;
    this.#dragInfo.eventElement = event.target.closest('.event');

    // Set the drag mode
    if (event.target.classList.contains('sizer')) {
      // HORIZONTAL DRAG
      event.target.classList.contains('end') ? this.#dragInfo.mode = 'endTime' : this.#dragInfo.mode = 'startTime';
    } else {
      // VERTICAL DRAG
      this.#dragInfo.mode = 'vertical';

      // Make it partially transparent
      event.target.classList.add('temp');
    }
  }

  drop(event) {
    // Preset some stuff:
    let assignmentUri = `/schedule/events/assignments/${this.#dragInfo.eventElement.dataset.assignmentId}`;
    let headers = {
      // Content type / CSRF stuffs:
      'Content-Type': 'application/json',
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
    };
    let params = {
      // Important, used to calculate grid column stuff:
      start_hour: this.startHourValue
    };

    // Do the actual work:
    switch (this.#dragInfo.mode) {
      case 'vertical':
        // Add new user id:
        params.schedule_events_assignmnet = {
          user_id: event.target.closest('.row').dataset.scheduleUserId
        };
        break;

      case 'startTime':
        let gcs = parseInt(this.#dragInfo.eventElement.style.gridColumnStart);
        let startHrHeader = this.hourTargets.find(t => parseInt(t.dataset.colIndex) <= gcs && parseInt(t.dataset.colIndex) + this.hoursDivisionsValue > gcs);
        let dgcs = gcs - parseInt(startHrHeader.dataset.colIndex);
        let startTime = new Date(startHrHeader.dataset.startTime);

        startTime = new Date(startTime.getTime() + (dgcs * 900000));
        params.schedule_events_assignmnet = {
          starts_at: startTime.toISOString()
        };
        break;

      case 'endTime':
        let gce = parseInt(this.#dragInfo.eventElement.style.gridColumnEnd);
        let endHrHeader = this.hourTargets.find(t => parseInt(t.dataset.colIndex) <= gce && parseInt(t.dataset.colIndex) + this.hoursDivisionsValue > gce);
        let dgce = gce - parseInt(endHrHeader.dataset.colIndex);
        let endTime = new Date(endHrHeader.dataset.startTime);

        endTime = new Date(endTime.getTime() + (dgce * 900000));
        params.schedule_events_assignmnet = {
          ends_at: endTime.toISOString()
        };
        break;
    }

    // Send Update:
    fetch(assignmentUri, { method: 'PUT', headers: headers, body: JSON.stringify(params) })
      .then((response) => response.text())
      .then((responseText) => {
        // Replace the element with the returned element, updating times/resetting style:
        this.#dragInfo.eventElement.outerHTML = responseText;
      });
    }

  dragOver(event) {
    // Allow Drop:
    event.preventDefault();

    switch (this.#dragInfo.mode) {
      case 'vertical':
        event.target.closest('.row').append(this.#dragInfo.eventElement);
        break;
      case 'startTime':
        if (event.clientX < this.hourTargets[0].getBoundingClientRect().x) {
          // Gone off the right edge into the name:
          this.#dragInfo.eventElement.style.gridColumnStart = 2;
        } else {
          let startHourHeader = this.hourTargets.find(hrs => (event.clientX >= hrs.getBoundingClientRect().x) && (event.clientX <= hrs.getBoundingClientRect().x + hrs.getBoundingClientRect().width));          
          let gcs = parseInt(startHourHeader.dataset.colIndex) + Math.floor((event.clientX - startHourHeader.getBoundingClientRect().x) / (startHourHeader.getBoundingClientRect().width / this.hoursDivisionsValue));
          if (gcs < parseInt(this.#dragInfo.eventElement.style.gridColumnEnd)) this.#dragInfo.eventElement.style.gridColumnStart = gcs;
        }
        break;
      case 'endTime':
        let endHourHeader = this.hourTargets.find(hrs => (event.clientX >= hrs.getBoundingClientRect().x) && (event.clientX <= hrs.getBoundingClientRect().x + hrs.getBoundingClientRect().width));
        let gce = parseInt(endHourHeader.dataset.colIndex) + Math.floor((event.clientX - endHourHeader.getBoundingClientRect().x) / (endHourHeader.getBoundingClientRect().width / this.hoursDivisionsValue));
        gce++;
        if (gce > parseInt(this.#dragInfo.eventElement.style.gridColumnStart)) this.#dragInfo.eventElement.style.gridColumnEnd = gce;
        break;
    }
  }
}