import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"

export default class extends Controller {
  static targets = ['tabBar', 'tabs']
  connect() {
    console.log( "Tab Panel Init" );
  }

  tab(event) {
    // Show the tab:
    new bootstrap.Tab(
      document.getElementById(
        `${event.params.tab.toUpperCase()}_TAB`
      )
    ).show();
  }
}
