import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="effects"
export default class extends Controller {
  connect() {
    
  }

  log() {
    console.log('here');
  }
}
