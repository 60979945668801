import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="maps"
export default class extends Controller {
  static targets = [ "mapElement", "userSelector" ];

  static values = {
    home: {
      type: Object,
      default: {

      }
    },
    users: {
      type: Array,
      default: []
    },
    events: {
      type: Array,
      default: []
    },
    userUrl: {
      type: String,
      default: ""
    },
    usersUrl: {
      type: String,
      default: ""
    }
  }

  connect() {
  }

  change(event) {
    //console.log(`SHOULD REDIRECT TO ${this.userUrlValue}`)
    if(this.userSelectorTarget.value != '') {
      window.location.href = this.userUrlValue.replace("USER_ID",this.userSelectorTarget.value); // `http://localhost:3000/users/${this.userSelectorTarget.value}/map`;
    } else {
      window.location.href = this.usersUrlValue;
    }

    //console.log(event);
  }

  initializeMap() {
    console.log('SHOULD INIT MAP');
    this.#mapObject();

    // Create a marker for the division home location:
    const marker = new window.google.maps.Marker({
      position: this.homeValue? {lat: this.homeValue.lat, lng: this.homeValue.lng} : {lat: 38.8976763, lng: -77.0387185},
      map: this.#mapObject(),
      title: this.homeValue.name,
      label: {
        fontFamily: 'bootstrap-icons',
        text: '\uF423',
        color: '#ffffff'
      }
    });

    // Create a marker for each user:
    this.usersValue.forEach((userJson, i) => {
      new window.google.maps.Marker({
        position: userJson,
        map: this.#mapObject(),
        title: userJson.name,
        label: {
          fontFamily: 'bootstrap-icons',
          text: '\uF5EA',
          color: '#ffffff'
        }
      });
    });

    // Create a shape for events:
    let eventCords = [];
    this.eventsValue.forEach((eventJson, i) => {
      eventCords.push(eventJson);
    });
    const breadcrumbTrail = new window.google.maps.Polyline({
      path: eventCords,
      geodesic: true,
      strokeColor: "#FF0000",
      strokeOpacity: 1.0,
      strokeWeight: 2,
    });
    breadcrumbTrail.setMap(this.#mapObject());
  }

// Pay attention to this function^^  use format after reading docs
  #mapObject() {
    if(this._map == undefined) {
      this._map = new window.google.maps.Map(this.mapElementTarget, {
        zoom: 11,
        center: this.homeValue? {lat: this.homeValue.lat, lng: this.homeValue.lng} : {lat: 38.8976763, lng: -77.0387185}
        //center: this.usersValue.length > 1 ? this.homeValue : this.usersValue[0]
      });
    }
    return this._map;
  }
}
