import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"

// Connects to data-controller="gravity"
export default class extends Controller {
  static values = {
    token: String,
    retryUri: String
  };
  static targets = [ "dataInput", "submitButton", 'billingName', 'billingAddress', 'billingPostal', 'processingForm', 'fieldErrorDialog', 'timeoutErrorDialog', 'disableSubmit' ];

  connect() {
    this.#configureModals();
    this.#setupEmergePay();
  }

  submitForm(event) {
    if(this.disableSubmitTarget.value == 'false') {
      // submit form
      console.log('submit form');
    } else {
      // Don't submit the form:
      event.preventDefault();

      // Show processing dx:
      new bootstrap.Modal(this.processingFormTarget, {
        keyboard: false,
        backdrop: 'static'
      }).show();

      // Check AVS fields:
      if(this.element.reportValidity()) {
        // Submit the emergepay fields:
        this.emergepayObject.process();

        // Start a 30-second timeout to watch for issues with Gravity:
        window.setTimeout(this.#timeOutError, 30000);
      } // else - show client-side errors
    }
  }

  #timeOutError() {
    const modal = new bootstrap.Modal(document.getElementById(this.timeoutErrorDialogTarget));
    modal.show();
  }

  #configureModals() {
    this.fieldErrorDialogTarget.addEventListener('hidden.bs.modal', event => {
      this.fieldErrorDialogTarget.querySelector('ul').innerHTML = '';
    });

    this.timeoutErrorDialogTarget.addEventListener('hidden.bs.modal', event => {
      location = this.retryUriValue;
    });
  }

  #setupEmergePay() {
    const defaultStyle = {
      "border": "1px solid #ced4da",
      "border-radius": "0.5rem",
      "font-size": "1rem",
      "font-weight": "400",
      "line-height": "1.5",
      "color": "#212529",
      "padding": "0.375rem 0.75rem"
    }
    this.emergepayObject = window.emergepayFormFields.init({
      transactionToken: this.tokenValue,
      transactionType: 'CreditSale',
      fieldSetUp: {
        cardNumber: {
          appendToSelector: "cardNumberContainer",
          useField: true,
          autoIframeHeight: true,
          styles: defaultStyle
        },
        cardExpirationDate: {
          appendToSelector: "expirationDateContainer",
          useField: true,
          autoIframeHeight: true,
          styles: defaultStyle
        },
        cardSecurityCode: {
          appendToSelector: "securityCodeContainer",
          useField: true,
          autoIframeHeight: true,
          styles: defaultStyle
        },
        totalAmount: {
          useField: false
        },
        externalTranId: {
          useField: false
        }
      },
      fieldErrorStyles: {
        border: "1px solid red"
      },
      onFieldsLoaded: () => {
        const submitButton = document.querySelector('[data-gravity-target="submitButton"]');
        submitButton.disabled = false;
      },
      onFieldError: (errorData) => {
        const processingModal = bootstrap.Modal.getInstance('[data-gravity-target="processingForm"]');
        const errorModal = bootstrap.Modal.getOrCreateInstance('[data-gravity-target="fieldErrorDialog"]');

        processingModal.hide();
        errorModal.show();

        const el = document.createElement('li');
        el.setAttribute('id', `ERROR_FOR_${errorData.fieldId}`); // don't really need this
        el.innerHTML = `${errorData.result} (${errorData.fieldId})`;
        errorModal._dialog.querySelector('ul').appendChild(el);
      },
      onUserAuthorized: function (transactionToken) {
        console.log('transactionToken', transactionToken);
        const form = document.querySelector('[data-controller="gravity"]');
        const disableSubmit = form.querySelector('[data-gravity-target="disableSubmit"]');

        disableSubmit.value = 'false';
        form.submit();
      }
    });
  }
}
