import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "numberOfMonths", "amountToCharge" ];
  static values = {
    rawCost: Number
  };

  connect() {
    console.log('INIT MONTHLY PMI');
  }

  updateMonthlyCost() {
    console.log('test');
    if (this.numberOfMonthsTarget.value.length > 0) {
      if(this.hasRawCostValue) {
        let totalCost = this.rawCostValue * (parseFloat(this.amountToChargeTarget.value) / 100.00);
      } else {
        let totalCost = parseFloat(this.amountToChargeTarget.value);
      }
      let monthly_amount = totalCost / parseFloat(this.numberOfMonthsTarget.value);
      // TODO: i18n
      this.numberOfMonthsTarget.parentElement.querySelector('.afterthought').innerHTML = `$${monthly_amount}/month`;
    }
  }
}
