import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="forms--questions"
export default class extends Controller {
  static targets = [ 'choicesSection', 'responseType', 'blankChoice', 'requiredChoice', 'requiredSection', 'multiSelect'];

  connect() {
    this.toggleChoices();
    this.toggleRequired();
  }

  toggleChoices() {
    if(this.responseTypeTarget.value == 'multiple_choice') {
      this.choicesSectionTarget.classList.remove('d-none');
      console.log(this.requiredSectionTarget.classList);
    } else {
      this.choicesSectionTarget.classList.add('d-none');
      console.log(this.choicesSectionTarget.classList);
    }
  }

  toggleRequired() {
    const checkBox = this.multiSelectTarget
    const requiredBox = this.requiredSectionTarget
    const requiredValue = this.requiredChoiceTarget
    
    if (checkBox.checked && this.responseTypeTarget.value == 'multiple_choice') {
      console.log(checkBox.value)
      console.log("Checkbox is checked..");
      requiredBox.classList.add('d-none');
      requiredValue.value = '0';
      console.log("removing required")
    } else {
      console.log(checkBox.value)
      console.log("Checkbox is not checked..");
      requiredBox.classList.remove('d-none');
      console.log("replacing required")
    }
    checkBox.addEventListener('change', function() {
      if (this.checked) {
        console.log(checkBox.value)
        console.log("Checkbox is checked..");
        requiredBox.classList.add('d-none');
        requiredValue.value = '0';
        
      } else {
        console.log(checkBox.value)
        console.log("Checkbox is not checked..");
        requiredBox.classList.remove('d-none');
      }
    });
    
  }

  destroyChoice(event) {
    event.preventDefault();
    const row = event.target.closest('.d-flex');
    const hiddenDeleteField = row.querySelector('.destroy-field');

    hiddenDeleteField.value = "1"; // Use the rails default for this, as if it were a checkBox
    row.classList.add('d-none');
  }

  addChoice(event) {
    event.preventDefault();
    let newRow = this.blankChoiceTarget.cloneNode(true);
    newRow.classList.remove('d-none');
    let rowsSection = this.choicesSectionTarget.querySelector('.questions-rows');
    let lastChoice = rowsSection.lastElementChild
    if(lastChoice.classList.contains('d-none')){
      rowsSection.appendChild(newRow);
    }else{
      let lastInput = lastChoice.querySelector('input');
      let currentX = parseInt(lastInput.name.match(/\[(\d+)\]/)[1]);
      let newX = currentX + 1;
      let input = newRow.querySelector('input');
      input.name = input.name.replace(/\[(\d+)\]/, '[' + newX + ']');
      rowsSection.appendChild(newRow);
    }
  }
}
