import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="equipment"
export default class extends Controller {
  static targets = ['years', 'expiration'];

  setExpireDate(event) {
    if (parseInt(event.target.value) > 0) {
      let oneYearFromNow = new Date();
      oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + parseInt(event.target.value));
      if (this.hasExpirationTarget) {
        this.expirationTarget.valueAsDate = oneYearFromNow;
      }
      this.removeYearsRemaining();
    }
  }

  removeYearsRemaining() {
    if (this.hasYearsTarget) {
      this.yearsTarget.remove();
    }
  }
}
