import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="filters"
export default class extends Controller {
  static targets = [
    'filterBar'
  ];

  connect() {
    if (location.search.length > 2 && !location.href.includes('dashboard')) {
      this.filterBarTarget.classList.remove('d-none');
    }
  }

  toggleFilterBar() {
    this.filterBarTarget.classList.toggle('d-none');
  }
}
