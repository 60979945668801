import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="charts"
export default class extends Controller {
  static values = {
    events: {
      type: Array,
      default: []
    },
    techs: {
      type: Array,
      default: []
    }
  };

  connect() {
    google.charts.load('current', {'packages':['timeline']});
    google.charts.setOnLoadCallback(this.drawChart.bind(this));
    /*
    google.charts.setOnLoadCallback(function () {
      var container = document.getElementById('timeline');
      var chart = new google.visualization.Timeline(container);
      var dataTable = new google.visualization.DataTable();

      dataTable.addColumn({ type: 'string', id: 'Tech' });
      dataTable.addColumn({ type: 'string', id: 'Name' });
      dataTable.addColumn({ type: 'date', id: 'Start' });
      dataTable.addColumn({ type: 'date', id: 'End' });

      console.log(this.techsValue.length);

      this.techsValue.forEach((user, i) => {
        console.log('foobar');
        dataTable.addRows([
          user['first_name'] + ' ' + user['last_name'],
          'customer',
          new Date(0,0,0,12,0,0),
          new Date(0,0,0,13,30,0)
        ]);
      });
      /*
      dataTable.addRows([
        [ 'Chris Clune', 'Eyal Gottlieb',       new Date(0,0,0,12,0,0),  new Date(0,0,0,13,30,0) ],
        [ 'Chris Clune', 'Eyal Gottlieb',    new Date(0,0,0,14,0,0),  new Date(0,0,0,15,30,0) ],
        [ 'Chris Clune', 'Eyal Gottlieb',        new Date(0,0,0,16,0,0),  new Date(0,0,0,17,30,0) ],
        [ 'Matt Schultz',   'Some Customer',    new Date(0,0,0,12,30,0), new Date(0,0,0,14,0,0) ],
        [ 'Matt Schultz',   'Some Customer', new Date(0,0,0,14,30,0), new Date(0,0,0,16,0,0) ],
        [ 'Matt Schultz',   'Some Customer',     new Date(0,0,0,16,30,0), new Date(0,0,0,18,0,0) ]]);

      chart.draw(dataTable);
    });
    */
  }

  drawChart() {
    console.log('should draw chart');
    console.log(this.techsValue.length);

    var container = document.getElementById('timeline');
    var chart = new google.visualization.Timeline(container);
    var dataTable = new google.visualization.DataTable();

    dataTable.addColumn({ type: 'string', id: 'Tech' });
    dataTable.addColumn({ type: 'string', id: 'Name' });
    dataTable.addColumn({ type: 'date', id: 'Start' });
    dataTable.addColumn({ type: 'date', id: 'End' });

    console.log(this.techsValue.length);

    this.techsValue.forEach((userJSON, i) => {
      const user = JSON.parse(userJSON);
      dataTable.addRows([[
        user.first_name + ' ' + user.last_name,
        'customer',
        new Date(0,0,0,12,0,0),
        new Date(0,0,0,13,30,0)
      ]]);
    });
    /*
    dataTable.addRows([
      [ 'Chris Clune', 'Eyal Gottlieb',       new Date(0,0,0,12,0,0),  new Date(0,0,0,13,30,0) ],
      [ 'Chris Clune', 'Eyal Gottlieb',    new Date(0,0,0,14,0,0),  new Date(0,0,0,15,30,0) ],
      [ 'Chris Clune', 'Eyal Gottlieb',        new Date(0,0,0,16,0,0),  new Date(0,0,0,17,30,0) ],
      [ 'Matt Schultz',   'Some Customer',    new Date(0,0,0,12,30,0), new Date(0,0,0,14,0,0) ],
      [ 'Matt Schultz',   'Some Customer', new Date(0,0,0,14,30,0), new Date(0,0,0,16,0,0) ],
      [ 'Matt Schultz',   'Some Customer',     new Date(0,0,0,16,30,0), new Date(0,0,0,18,0,0) ]]);
    */
    chart.draw(dataTable);
  }
}
