import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="customers--merge"
export default class extends Controller {
  static targets = [ 'selection', 'button', 'count' ];

  count() {
    let selectedCount = 0;
    this.selectionTargets.forEach((checkbox) => {
      if(checkbox.checked) { selectedCount++; }
    });

    this.countTarget.innerHTML = selectedCount.toString();

    if(selectedCount > 1) {
      this.buttonTarget.classList.remove('disabled');
    } else {
      this.buttonTarget.classList.add('disabled');
    }
  }
}
