import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="donutchart"
export default class extends Controller {
  static values = {
    totalWon: Number,
    totalGoal: Number,
    showLabel: Boolean
  }

  connect() {
    const data2 = {
      visibleLabel: false,
      visibleForStick: false,
      labelColor: "#000000",
      stickColor: "#000000",
      mainText: "$8.6M",
      mainTextColor: "#3a3541DE",
      subText: "Weekly Events",
      subTextColor: "#3a354199",
      data: [
          {
              label: "",
              value: 13,
              color: "#05476E"
          },
          {
              label: "",
              value: 25,
              color: "#05476EB3"
          },
          {
              label: "",
              value: 12,
              color: "#05476E80"
          },
          {
              label: "",
              value: 50,
              color: "#F4F5FA"
          },
      ]
    };
    const data1 = {
        visibleLabel: true,
        visibleForStick: this.showLabelValue,
        labelColor: "#000000",
        stickColor: "#000000",
        mainText: "$8.6M",
        mainTextColor: "#3a3541DE",
        subText: !this.showLabelValue ? "Weekly Events" : "",
        subTextColor: !this.showLabelValue ? "#3a354199" : "",
        data: [
            {
                label: "value",
                value: 45,
                color: "#05476E"
            },
            {
                label: "value",
                value: 55,
                color: "#05476E80"
            },
        ]
    };

    (() => {
        const el = d3.select(this.element);
        const margin = 0;
        const width = el.node().offsetWidth;
        const height = el.node().offsetHeight;

        const radius = Math.min(width, height) / 2 - margin;

        const svg = el
            .append("svg")
            .attr("width", width)
            .attr("height", height)
            .append("g")
            .attr("transform", `translate(${width / 2},${height / 2})`);

        function update(param) {
            const data = param.data;
            const pie = d3.pie()
                .sort(null)
                .value(function (d) { return d["value"]; })
            const data_ready = pie(data)

            const arc = d3.arc()
                .innerRadius(radius * 0.55)
                .outerRadius(radius * 0.8)

            const outerArc = d3.arc()
                .innerRadius(radius)
                .outerRadius(radius)

            svg
                .selectAll('allSlices')
                .data(data_ready)
                .join('path')
                .attr('d', arc)
                .attr('fill', function (d) { return (d.data["color"]) })
                .style("opacity", 1)

            if (param.visibleForStick) {
                svg
                    .selectAll('allPolylines')
                    .data(data_ready)
                    .join('polyline')
                    .attr("stroke", param.stickColor ? param.stickColor : "#000000")
                    .style("fill", "none")
                    .attr("stroke-width", 1)
                    .attr('points', function (d) {
                        const c = arc.centroid(d),
                            x = c[0],
                            y = c[1],
                            h = Math.sqrt(x * x + y * y);
                        const posA = [(x / h * radius * 0.8), (y / h * radius * 0.8)]
                        const posB = outerArc.centroid(d)
                        return [posA, posB]
                    })
            }

            if (param.visibleLabel) {
                svg
                    .selectAll('allLabels')
                    .data(data_ready)
                    .join('text')
                    .text(d => d.data["label"])
                    .attr("class", "label")
                    .attr("transform", function (d) {
                        const c = outerArc.centroid(d),
                            x = c[0],
                            y = c[1],
                            h = Math.sqrt(x * x + y * y),
                            labelr = radius * 1.05;
                        return "translate(" + (x / h * labelr) + ',' + (y / h * labelr) + ")";
                    })
                    .style("text-anchor", function (d) {
                        return (d.endAngle + d.startAngle) / 2 > Math.PI ? "end" : "start";
                    })
                    .style("fill", param.labelColor ? param.labelColor : "#000000")
                    .style("font-size", width / 24)
            }

            if (param.mainText) {
                const mainText = svg
                    .append('text')
                    .text(param.mainText)
                    .attr("class", "main-text")
                    .style("text-anchor", "middle")
                    .style("fill", param.mainTextColor ? param.mainTextColor : "#000000")
                    .style("font-size", width / 14)
                if (param.subText)
                    mainText.attr('x', '0').attr('y', '-0.2em');
                else
                    mainText.attr('x', '0').attr('y', '0.2em');
            }
            if (param.subText) {
                svg
                    .append('text')
                    .text(param.subText)
                    .attr("class", "sub-text")
                    .style("text-anchor", "middle")
                    .style("fill", param.subTextColor ? param.subTextColor : "#000000")
                    .style("font-size", width / 28)
                    .attr('x', '0')
                    .attr('y', '1em');
            }
        }
        update(data1)
    })();
  }
}
