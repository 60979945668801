import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"

// Connects to data-controller="builders--multiselect"
export default class extends Controller {
  static targets = [
    'checkBox',
    'modal',
    'option',
    'selectedItem',
    'textBox'
  ];

  connect() {
    this.#modal = new bootstrap.Modal(this.modalTarget);
  }

  click(event) {
    switch (event.target.tagName) {
      case 'I':
        this.#removeOption(event.target.closest('.selected-item').dataset.value);
        break;
      default:
        this.#openModal();
        break;
    }
  }

  toggleOption(event) {
    if(event.target.checked) {
      this.#addOption(event.params.value);
    } else {
      this.#removeOption(event.params.value);
    }
  }

  // Private

  #modal;

  #openModal() {
    this.#modal.toggle();
  }

  #removeOption(option) {
    this.selectedItemTargets.find(si => si.dataset.value == option)?.remove();
    this.optionTargets.find(si => si.value == option)?.removeAttribute('selected');
    this.checkBoxTargets.find(si => si.getAttribute('data-builders--multiselect-value-param') == option).removeAttribute('checked');
  }
  
  #addOption(option) {
    let optionElement = this.optionTargets.find(si => si.value == option);
    optionElement.setAttribute('selected', '');
    this.checkBoxTargets.find(si => si.getAttribute('data-builders--multiselect-value-param') == option).setAttribute('checked', '');
    let newSelectedItem = document.createElement("div");
    newSelectedItem.setAttribute('data-value', option);
    newSelectedItem.setAttribute('data-builders--multiselect-target', 'selectedItem');
    newSelectedItem.classList.add('selected-item');
    let newSelectedItemX = document.createElement("i");
    newSelectedItemX.classList.add('bi');
    newSelectedItemX.classList.add('bi-x');
    let newSelectedItemText = document.createTextNode(optionElement.innerHTML);
    newSelectedItem.appendChild(newSelectedItemText);
    newSelectedItem.appendChild(newSelectedItemX);
    this.textBoxTarget.appendChild(newSelectedItem);
  }
}
