import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="timeline"
export default class extends Controller {
  static targets = [
    "addressId",
    "addressSection",
    "customerContactId",
    'event',
    'followupDetailsSection',
    'followupIdSelect',
    'followupSection',
    'hoursHeader',
    "newVisit",
    "newAbsence",
    "newTask",
    'schedulableId',
    'schedulableSection',
    'schedulableType',
    'startTime',
    'suggestedTechSection',
    'suggestedTechList',
    "techs",
    "userSelector",
    'visitTypeSection',
    'visitTypeSelect'
  ];

  connect() {
    if(this.hasVisitTypeSectionTarget) {
      this.visitTypeSectionTarget.classList.add('d-none');
    }
    this.#sizeEvents();
  }

  newVisitFor(event) {
    this.#hideAll();
    this.newVisitTarget.classList.remove('d-none');
    if(event.params.userId != null) {
      this.techsTargets.forEach((targ) => {
        targ.value = event.params.userId;
      });
      this.userSelectorTargets.forEach((selector) => {
        const selectorController = this.application.getControllerForElementAndIdentifier(selector, 'users--select-multiple')
        if(selectorController != null) {
          selectorController.updateSelectedTechs();
        }
      });
    }
    this.#sizeEvents();
  }

  newAbsenceFor(event) {
    this.#hideAll();
    this.newAbsenceTarget.classList.remove('d-none');
    if(event.params.userId != null) {
      this.techsTargets.forEach((targ) => {
        targ.value = event.params.userId;
      });
      this.userSelectorTargets.forEach((selector) => {
        const selectorController = this.application.getControllerForElementAndIdentifier(selector, 'users--select-multiple')
        if(selectorController != null) {
          selectorController.updateSelectedTechs();
        }
      });
    }
    this.#sizeEvents();
  }

  newTaskFor(event) {
    this.#hideAll();
    this.newTaskTarget.classList.remove('d-none');
    if(event.params.userId != null) {
      this.techsTargets.forEach((targ) => {
        targ.value = event.params.userId;
      });
      this.userSelectorTargets.forEach((selector) => {
        const selectorController = this.application.getControllerForElementAndIdentifier(selector, 'users--select-multiple')
        if(selectorController != null) {
          selectorController.updateSelectedTechs();
        }
      });
    }
    this.#sizeEvents();
  }

  getAddresses(event) {
    const customerId = event.target.value;
    const addressUri = `/customers/${customerId}/addresses`;

    fetch(addressUri)
      .then((response) => response.text())
      .then((data) => {
        this.addressIdTarget.innerHTML = data;
        this.addressIdTarget.setAttribute('required','');

        const contactsUri = `/customers/${customerId}/customer_contacts`;
        fetch(contactsUri)
          .then((response) => response.text())
          .then((data) => {
            this.customerContactIdTarget.innerHTML = data;
            this.addressSectionTarget.classList.remove('d-none');
        });
      });
  }

  getJobs(event) {
    const addressId = event.target.value;
    const jobsUri = `/addresses/${addressId}/jobs`;
    const time = '';
    const followupOptionsUri = `/addresses/${addressId}/schedule/events/get_followup_options?time=${time}`;
    this.schedulableSectionTarget.classList.remove('d-none');
    this.visitTypeSectionTarget.classList.remove('d-none');
    this.followupSectionTarget.classList.remove('d-none');
    this.schedulableTypeTarget.value = 'Job';
    fetch(jobsUri)
      .then((response) => response.text())
      .then((data) => {
        this.schedulableIdTarget.innerHTML = data;
      });
    fetch(followupOptionsUri)
      .then((response) => response.text())
      .then((data) => {
        this.followupIdSelectTarget.innerHTML = data;
      });
  }

  getSuggestedTechs(event) {
    //const addressId = event.target.value;
    this.suggestedTechSectionTarget.querySelector('ul').innerHTML = '';
    if (this.visitTypeSelectTarget.value != "" && this.visitTypeSelectTarget.value != null)
    {
      const techsUri = `/users/suggestions?selected_event_category_id=${this.visitTypeSelectTarget.value}&selected_address_id=${this.addressIdTarget.value}&start_time=${this.startTimeTarget.value}`;
      this.suggestedTechSectionTarget.classList.remove('d-none');
      fetch(techsUri)
        .then((response) => response.text())
        .then((data) => {
          this.suggestedTechSectionTarget.querySelector('ul').innerHTML = data;
        });
    }
  }

  getFollowupOptions(event) {
    this.suggestedTechSectionTarget.classList.remove('d-none');
    fetch(techsUri)
      .then((response) => response.text())
      .then((data) => {
        this.suggestedTechSectionTarget.querySelector('ul').innerHTML = data;
      });
  }

  toggleShowJobsList(event) {
    if(event.target.checked) {
      this.schedulableIdTarget.classList.add('d-none');
      this.schedulableIdTarget.value = '';
      this.schedulableTypeTarget.value = '';
    } else {
      this.schedulableIdTarget.classList.remove('d-none');
      this.schedulableTypeTarget.value = 'Job';
    }
    console.log(`Should show/hide jobs list, clearing it during a hide`);
  }

  toggleShowFollowupSection(event) {
    if(event.target.checked) {
      this.followupDetailsSectionTarget.classList.remove('d-none');
    } else {
      this.followupDetailsSectionTarget.classList.add('d-none');
    }
  }

  #hideAll() {
    this.newVisitTarget.classList.add('d-none');
    this.newAbsenceTarget.classList.add('d-none');
    this.newTaskTarget.classList.add('d-none');
  }

  #sizeEvents() {
    if (this.hasHoursHeaderTarget) {
      let baseWidth = this.hoursHeaderTarget.offsetWidth;
      this.eventTargets.forEach((eventElement) => {
        eventElement.style.setProperty('margin-left', `${(eventElement.dataset.startMin / 60) * baseWidth}px`);
        if (eventElement.dataset.endMin > 0) {
          eventElement.style.setProperty('margin-right', `${((60 - eventElement.dataset.endMin) / 60) * baseWidth}px`);
        }
      });
    }
  }
}
