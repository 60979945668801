import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    let data = [
      { category: "Green", category_amount: parseFloat(this.element.dataset.green), fill_color: '#33a02c' },
      { category: "Red", category_amount: parseFloat(this.element.dataset.red), fill_color: '#e31a1c' }
    ];

    let baseEl = d3.select(this.element);
    let svgEl = baseEl.append('svg');

    // Set the graph to full-width, based on the rendered width of it's container:
    svgEl.attr('width', this.element.clientWidth).attr('height', this.element.clientWidth);

    let mainRad = parseInt(this.element.clientWidth)/2,
        donutRad = 0,
        labelRad = mainRad / 3,
        labelSize = 8;

    let pie = d3.pie().value(d=>d.category_amount)(data); //.padAngle(0.025)(data);

    let arcMkr = d3.arc().innerRadius(donutRad).outerRadius(mainRad); //.cornerRadius(5);

    let g = svgEl.append('g')
      .attr('transform', `translate(${mainRad},${mainRad})`);

    g.selectAll('path')
      .data( pie )
      .enter()
      .append('path')
      .attr('d', arcMkr)
      .attr('fill', d=>d.data.fill_color);
  }
}
