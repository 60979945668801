import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"

// Connects to data-controller="item-select"
export default class extends Controller {
  static targets = [ "modal", "content", "text", "id", "backButton" ];
  static values = {
    url: String
  }

  #modalElement;

  async connect() {
    this.#modalElement = new bootstrap.Modal(this.modalTarget);
    //console.log(this.#modalElement)
    this.back()
  }

  async open(event) {
    console.log('Should Open');

    let response = await fetch(this.urlValue);
    let content = await response.text();
    this.contentTarget.innerHTML = content;

    this.#modalElement.show();
    event.target.blur();
  }

  async back(event){
    const backButton = this.backButtonTarget
    backButton.addEventListener('click', function(){
      backButton.classList.add('d-none')
    });
  }
  async select(event) {
    event.preventDefault();
    this.modalTarget.dataset.selectedDetails = JSON.stringify(event.params.details);
    console.log(this.modalTarget.dataset.selectedDetails)
    let response = null;
    let content = null;
    let backButton = this.backButtonTarget
    switch(event.params.details.type) {
      case 'LineItemVariant':
        console.log('Should Select Variant');
        console.log(`VAR: ${this.textTarget.value}`);
        this.#modalElement.hide();
        this.textTarget.value = event.params.details.name;
        this.idTarget.value = event.params.details.id;
        console.log(this.textTarget.value)
        console.log(this.idTarget.value)
        break;
      case 'LineItem':
        console.log('Should Open Variant Select');
        // TODO - Check if there are multiple variants; if not; automatically select.
        response = await fetch(event.params.url);
        content = await response.text();
        this.contentTarget.innerHTML = content;
        break;
      case 'LineItemCategory':
        console.log('Should Open Category');
        response = await fetch(event.params.url);
        content = await response.text();
        this.contentTarget.innerHTML = content;
        backButton.classList.remove('d-none')
        break;
    }
  }

  async closed(event) {
    console.log("I closed!");
    this.dispatch('close')
  }
}
