import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'show', 'edit' ]

  #setVisibility(mode) {
    switch(mode) {
      case 'edit':
        this.showTargets.forEach(e => e.classList.add("d-none"));
        this.editTargets.forEach(e => e.classList.remove("d-none"));
        break;
      case 'show':
        this.showTargets.forEach(e => e.classList.remove("d-none"));
        this.editTargets.forEach(e => e.classList.add("d-none"));
        break;
    }
  }

  connect() {
    console.log('Inline Form Init');
    this.#setVisibility('show');
  }

  swap(event) {
    this.#setVisibility('edit');
    event.preventDefault();
  }

  cancel(event) {
    this.#setVisibility('show');
    this.element.reset();
    event.preventDefault();
  }
}
