import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="forms"
export default class extends Controller {
  static targets = [
    'appearancesSection',
    'endDate',
    'endTime',
    'relationType',
    'startDate',
    'startTime',
    'timeField'
  ];

  connect() {
  }

  setNow() {
    if (this.hasTimeFieldTarget) {
      this.timeFieldTarget.value = this.#asYMDHM(new Date);
    }
  }

  setLastWeek(event) {
    if (this.hasStartDateTarget) {
      console.log(this.#startOfLastWeek());
      this.startDateTarget.value = this.#asYMD(this.#startOfLastWeek());
    }
    if (this.hasEndDateTarget) {
      console.log(this.#endOfLastWeek());
      this.endDateTarget.value = this.#asYMD(this.#endOfLastWeek());
    }
  }

  setThisWeek(event) {
    if (this.hasStartDateTarget) {
      this.startDateTarget.value = this.#asYMD(this.#startOfWeek());
    }
    if (this.hasEndDateTarget) {
      this.endDateTarget.value = this.#asYMD(this.#endOfWeek());
    }
  }

  setLastMonth(event) {
    if (this.hasStartDateTarget) {
      console.log(this.#startOfLastMonth());
      this.startDateTarget.value = this.#asYMD(this.#startOfLastMonth());
    }
    if (this.hasEndDateTarget) {
      console.log(this.#endOfLastMonth());
      this.endDateTarget.value = this.#asYMD(this.#endOfLastMonth());
    }
  }

  setThisMonth(event) {
    if (this.hasStartDateTarget) {
      this.startDateTarget.value = this.#asYMD(this.#startOfMonth());
    }
    if (this.hasEndDateTarget) {
      this.endDateTarget.value = this.#asYMD(this.#endOfMonth());
    }
  }

  // Calls the form submit. This can be used from link tags to make submission links.
  submit(event) {
    this.element.submit();
  }

  toggleAppearance() {
    if(this.relationTypeTarget.value == 'unrelated') {
      this.appearancesSectionTarget.classList.add('d-none');
    } else {
      this.appearancesSectionTarget.classList.remove('d-none');
    }
  }

  #startOfWeek() {
    var curr = new Date; // get current date
    var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week

    let start = new Date(curr.setDate(first));
    start.setHours(0,0,0,0);
    return start;
  }

  #startOfLastWeek() {
    var output = this.#startOfWeek();
    output.setHours(-168);
    return output;
  }

  #endOfLastWeek() {
    var output = this.#endOfWeek();
    output.setHours(-168);
    return output;
  }

  #endOfWeek() {
    var curr = new Date; // get current date
    let end = new Date(curr.setDate((curr.getDate() - curr.getDay()) + 6));
    end.setHours(23, 59, 59, 999);
    return end;
  }

  #startOfMonth() {
    let output = new Date();
    output.setDate(1);
    output.setHours(0, 0, 0, 0);
    return output;
  }

  #endOfMonth() {
    let output = this.#startOfMonth();
    output.setMonth(output.getMonth() + 1);
    output.setMilliseconds(-1);
    return output;
  }

  #startOfLastMonth() {
    let output = this.#startOfMonth();
    output.setMonth(-1);
    return output;
  }

  #endOfLastMonth() {
    let output = this.#startOfMonth();
    output.setMilliseconds(-1);
    return output;
  }

  #asYMD(date) {
    // "yyyy-MM-dd"
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${(date.getDate()).toString().padStart(2, '0') }`
  }

  #asYMDHM(time) {
    // "yyyy-MM-ddThh:mm"
    return `${this.#asYMD(time)}T${(time.getHours() + 1).toString().padStart(2, '0')}:${(time.getMinutes() + 1).toString().padStart(2, '0')}`
  }
}
