import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="sidebar"
export default class extends Controller {
  show() {
    this.element.querySelectorAll('.leftside_menu_text_dp').forEach((sidebarText) => sidebarText.classList.remove('d-none'));
  }

  hide() {
    this.element.querySelectorAll('.leftside_menu_text_dp').forEach((sidebarText) => sidebarText.classList.add('d-none'));
  }
}
