import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="areachart"
export default class extends Controller {
  static values = {
    totalWon: Number,
    totalGoal: Number,
    showLabel: Boolean
  }

  connect() {
    const el = d3.select(this.element);
    const width = Math.max(600, el.node().offsetWidth);
    const height = el.node().offsetHeight;

    var input = [23, 48, 77, 28, 91];
    var padding = { top: 7, bottom: 55, left: 7, right: 7 };
    var labelXPaddingBottom = 23;
    var labelXContent = { width: 30, height: 24 };
    var gridX = [0, 25, 50, 75, 100];
    var intervalY = (height - padding.top - padding.bottom) / (gridX.length - 1);

    var data_area = [];
    var valueX = makeArr(padding.left + labelXContent.width, width - padding.right, input.length);
    var labelX = ['Google', 'TV', 'Amazon', 'Apple', 'Meta'];
    for (var index = 0; index < input.length; index++) {
        data_area.push({
            name: valueX[index],
            value: input[index]
        })
    }
    var data_x = data_area.map(function (el) { return el.name; })
    var data_y = data_area.map(function (el) { return el.value; })

    var scaleY = d3.scaleLinear()
        .domain([d3.min(input), d3.max(input)])
        .range([padding.bottom, height - padding.top]);

    var chart = el
        .append("svg")        
        .attr("viewBox", `0 0 ${width} ${height}`)
        .style("min-width", width)

    chart.selectAll('line')
        .data(data_y)
        .enter()
        .append('line')
        .attr('x1', padding.left + labelXContent.width)
        .attr('x2', width - padding.right)
        .attr('y1', function (d, i) {
            return height - padding.bottom - intervalY * i;
        })
        .attr('y2', function (d, i) {
            return height - padding.bottom - intervalY * i;
        })
        .attr('stroke-dasharray', '10, 15')
        .attr('stroke-linecap', 'round')
        .style('stroke', '#d8dde1')
        .attr('stroke-width', 3);

    chart.selectAll('text.y')
        .data(gridX)
        .enter()
        .append("text")
        .style('font-size', '12px')
        .style('font-family', 'Poppins')
        .style('font-weight', '400')
        .style('line-height', labelXContent.height)
        .attr('fill', '#707683')
        .attr("x", padding.left)
        .attr("y", padding.top + 12 / 2)
        .attr('dx', 0)
        .attr('dy', function (d, i) {
            return i * intervalY;
        })
        .text(function (d, i) { return gridX[gridX.length - i - 1]; });

    chart.selectAll('text.x')
        .data(labelX)
        .enter()
        .append("text")
        .attr('fill', '#707683')
        .style('font-size', '12px')
        .style('font-family', 'Poppins')
        .style('font-weight', '400')
        .attr('width', (width - padding.left - padding.right - labelXContent.width) / (labelX.length - 1))
        .attr("x", padding.left + labelXContent.width)
        .attr("y", height - labelXPaddingBottom)
        .attr('dy', 0)
        .attr('dx', function (d, i) {
            return i * 0.97 * (width - padding.left - padding.right - labelXContent.width) / (labelX.length - 1);
        })
        .text(function (d, i) { return d });

    var Gen = d3.area()
        .curve(d3.curveMonotoneX)
        .x((p) => p.name)
        .y0(() => height - padding.bottom)
        .y1((p) => height - scaleY(p.value));

    chart.append("path")
        .datum(data_area)
        .attr("fill", "#e7f5fe")
        .attr("stroke", "none")
        .style('opacity', 0.5)
        .attr("d", Gen);

    chart.append("path")
        .datum(data_area)
        .attr("fill", "none")
        .attr("stroke", "#109cf1")
        .attr("stroke-width", 1.5)
        .attr("d", d3.line()
            .curve(d3.curveMonotoneX)
            .x(function (d) { return d.name })
            .y(function (d) { return height - scaleY(d.value) }));

    const mouse_g = chart.append('g').classed('mouse', true).style('display', 'none');
    mouse_g.append('line').attr('stroke', 'lightgrey').attr('stroke-width', 2);
    mouse_g.append('circle').attr('r', 6).attr("fill", "#109cf1").style('display', 'none');
    mouse_g.append('circle').attr('r', 4).attr("fill", "#ffffff").style('display', 'none');

    chart.on("mouseover", function (mouse) {
        mouse_g.style('display', 'block');
    });

    const [min_year, max_year] = d3.extent(data_area, d => d.name);
    chart.on("mousemove", function (mouse) {
        const [x_cord, y_cord] = d3.pointer(mouse);
        if (x_cord - padding.left >= 0) {
            const ratio = (x_cord - padding.left - labelXContent.width) / (width - padding.left - padding.right - labelXContent.width);

            const current_year = min_year + Math.round(ratio * (max_year - min_year));
            const cnt_array = data_area.find(item => Math.abs(item.name - current_year) < 10);
            if (cnt_array != null && cnt_array != undefined) {
                let cnt = cnt_array.value;
                mouse_g.select('line').attr('x1', cnt_array.name).attr('x2', cnt_array.name).attr('y1', padding.top).attr('y2', height - padding.bottom);
                mouse_g.selectAll('circle').attr('cy', height - scaleY(cnt)).attr('cx', cnt_array.name);
            }
        }
    });

    chart.on("mouseout", function (mouse) {
        mouse_g.style('display', 'none');
        mouse_g.selectAll('circle').style('display', 'block');
    });

    function makeArr(startValue, stopValue, cardinality) {
        var arr = [];
        var step = (stopValue - startValue) / (cardinality - 1);
        for (var i = 0; i < cardinality; i++) {
            arr.push(startValue + (step * i));
        }
        return arr;
    }
  }
}
