import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="bardoublechart"
export default class extends Controller {
  static values = {
    totalWon: Number,
    totalGoal: Number,
    showLabel: Boolean
  }

  connect() {
    const data1 = {
        dataColumns: ["group", "exist", "new"],
        colors: { exist: "#8A8D93", new: "#F6871F" },
        labels: { new: "New customer", exist: "Existing customer" },
        data: [
            { group: "Mon", exist: 20, new: 12 },
            { group: "Tue", exist: 10, new: 28 },
            { group: "Wed", exist: 8, new: 45 },
            { group: "Thu", exist: 25, new: 40 },
            { group: "Fri", exist: 12, new: 15 }
        ]
    };
    
    (() => {
        const el = d3.select(this.element);
        const margin = 10;
        const width = el.node().offsetWidth - margin;
        const height = el.node().offsetHeight ? el.node().offsetHeight - margin * 2 : width * 0.7;
    
        const tooltip = el
            .append("div")
            .style("position", "relative")
            .style("width", "100%")
            .append("div")
            .style("position", "absolute")
            .style("opacity", 0)
            .attr("class", "tooltip")
            .style("background-color", "white")
            .style("border", "solid")
            .style("border-width", "1px")
            .style("border-radius", "5px")
            .style("padding", "10px");
    
        const svg = el
            .append("svg")
            .attr("viewBox", `0 0 ${width + margin} ${height + margin * 2}`)
            .append("g")
            .attr("transform", `translate(${margin},${margin})`);
    
        const x = d3.scaleBand()
            .range([0, width])
            // .padding(0.05);
        const xAxis = svg.append("g")
            .attr("class", "myXaxis")
            .attr("transform", `translate(0,${height})`)
            .attr('stroke-width', 0)
    
        const y = d3.scaleLinear()
            .range([height, 0]);
        const yAxis = svg.append("g")
            .attr("class", "myYaxis")
            .attr('stroke-width', 0)
    
        function update(param) {
            const data = param.data
            const subgroups = param.dataColumns.slice(1)
    
            x.domain(data.map(d => d.group))
            xAxis.call(d3.axisBottom(x))
    
            y.domain([0, d3.max(data, d => {
                return Math.max(...subgroups.map(function (key) { return d[key] }))
            }
            )]);
            yAxis.call(d3.axisLeft(y).ticks(5));
    
            const xSubgroup = d3.scaleBand()
                .domain(subgroups)
                .range([0, x.bandwidth()])
                // .padding([0.3])
    
            svg.append("g")
                .selectAll("g")
                .data(data)
                .join("g")
                .attr("transform", d => `translate(${x(d.group)}, 0)`)
                .selectAll("rect")
                .data(function (d) { return subgroups.map(function (key, index) { return { key: key, value: d[key], index: index }; }); })
                .join("rect")
                .attr("x", d => d.index == 0?xSubgroup(d.key)+xSubgroup.bandwidth()-20 : xSubgroup(d.key)+2)
                .attr("y", d => y(d.value))
                .attr("width", 18)
                .attr("height", d => height - y(d.value))
                .attr("fill", d => param.colors[d.key])
                .attr('rx', 10)
                .on('mouseover', (d) => {
                    tooltip.text(d.target.__data__.value)
                        .style("opacity", 1);
                })
                .on("mousemove", (d) => {
                    tooltip.style("transform", `translate(20%, -100%)`)
                        .style("left", (d.offsetX) + "px")
                        .style("top", (d.offsetY) + "px")
                })
                .on('mouseleave', () => tooltip.style('opacity', 0));
        }
        update(data1)
    })();
  }
}
