import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"

// Connects to data-controller="processing"
export default class extends Controller {
  static targets = [ 'dialog', 'close' ];

  showDialog(event) {
    // Close any open dialogs:
    this.closeTargets.forEach((dialogToClose) => {
      const modalToClose = bootstrap.Modal.getInstance(dialogToClose);
      if(modalToClose != null) {
        modalToClose.hide();
      }
    });

    // Show processing dialog:
    new bootstrap.Modal(this.dialogTarget, {
      keyboard: false,
      backdrop: 'static'
    }).show();
  }
}
