import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="time-clock-entries"
export default class extends Controller {
  static targets = ['form', 'button', 'checkbox'];

  toggleEntry(event) {
    const selectElement = this.formTarget.querySelector('select[name="ids"]');
    let newSelectContents = '';
    this.checkboxTargets.forEach(cb => {
      if(cb.checked) {
        newSelectContents += `<option value="${cb.value}" selected></option>`
      }
    });
    selectElement.innerHTML = newSelectContents;
    if (selectElement.value) {
      this.buttonTarget.classList.remove('d-none');
    } else {
      this.buttonTarget.classList.add('d-none');
    }
  }
}
