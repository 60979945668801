import { Application } from "@hotwired/stimulus"
import { Autocomplete } from 'stimulus-autocomplete'

// Core Tools:
import * as bootstrap from "bootstrap"
//import * as d3 from 'd3'

// Stimulus Components:
//import ContentLoader from 'stimulus-content-loader'
//import ColorPicker from 'stimulus-color-picker';
import Sortable from 'stimulus-sortable';

const application = Application.start();

// Packages:
application.register('autocomplete', Autocomplete);

// Stimulus Components:
//application.register('color-picker', ColorPicker);
//application.register('content-loader', ContentLoader);
application.register('sortable', Sortable);

// Configure Stimulus development experience
application.debug = false;
window.Stimulus   = application;

export { application }

// Turn on popovers and tooltips:
let popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
let popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
  return new bootstrap.Popover(popoverTriggerEl);
});

var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
  return new bootstrap.Tooltip(tooltipTriggerEl);
});

// Disable submit buttons on click, preventing double clicks:
document.querySelectorAll('form').forEach((form) => {
  form.addEventListener('submit', (event) => {
    form.querySelectorAll('input[type="submit"]:not(.no-debounce)').forEach((submitButton) => {
      let newButton = document.createElement('button');
      newButton.setAttribute('name', submitButton.getAttribute('name'));
      newButton.setAttribute('value', submitButton.getAttribute('value'));
      newButton.setAttribute('class', submitButton.getAttribute('class'));
      for (var d in submitButton.dataset) {
        newButton.dataset[d] = submitButton.dataset[d];
      }
      newButton.dataset.debounced = 'true';
      newButton.innerHTML = `<div class="spinner-border spinner-border-sm me-2" role="status"><span class="visually-hidden">Loading...</span></div>${submitButton.getAttribute('value')}`;
      newButton.disabled = true;
      submitButton.replaceWith(newButton);
    });
  });
});

window.onhashchange = function () {
  document.querySelectorAll('[data-debounced="true"]').forEach((disabledButton) => {
    disabledButton.disabled = false;
  });
}