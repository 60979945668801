import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="graphs--super-pie"
export default class extends Controller {
  static values = {
    graphData: Array,
    options: Object
  }

  connect() {
    const width = this.element.offsetWidth;
    const height = this.element.offsetHeight;
    const radius = Math.min(width, height) / 2;
    const donutWidth = 50; //This is the width of the donut bar
    const nameField = this.optionsValue.nameField;
    const colorField = this.optionsValue.colorField;
    const sets = this.optionsValue.sets;
    //const showLegend = this.optionsValue.showLegend;
    const color = d3.scaleOrdinal(d3.schemePaired);

    // Base Donut
    var svg = d3.select(this.element)
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', 'translate(' + (width / 2) + ',' + (height / 2) + ')')
      .attr('class', 'shadow');
    
    sets.forEach((dataSet, index) => {
      if(index != 2) { return null; }
      let outerRadius = (radius / sets.length) * (index + 1);
      let innerRadius = (radius / sets.length) * (index);
      let arc = d3.arc().outerRadius(outerRadius).innerRadius(innerRadius);
      let pie = d3.pie().value(function (d) {
        return d[1][dataSet];
      });/*
      var tooltipDiv = d3.select(this.element).append("div")
        .attr("class", "tooltip-donut")
        .style("opacity", 0)
        .style("position", 'fixed');
        */
      svg.selectAll('path')
        .data(pie(this.graphDataValue))
        .enter()
        .append('path')
        .attr('d', arc)
        .attr('fill', function (d, i) {
          let obj = d.data[0];
          if (obj == null) {
            return '#DDDDDD';
          } else {
            return obj[colorField];
          }
        })
        .attr('transform', 'translate(0, 0)');
    });
    /*
    var arc = d3.arc().outerRadius(radius).innerRadius(radius - donutWidth);
    var pie = d3.pie()
      .value(function (d) {
        //return d[1][0][nameField];
        return d[1][sets[0]];
      });
    
    // mouseover
    var tooltipDiv = d3.select(this.element).append("div")
      .attr("class", "tooltip-donut")
      .style("opacity", 0)
      .style("position", 'fixed');
    const path = svg.selectAll('path')
      .data(pie(this.graphDataValue))
      .enter()
      .append('path')
      .attr('d', arc)
      .attr('fill', function (d, i) {
        let obj = d.data[0];
        if(obj == null) {
          return '#DDDDDD';
        } else {
          return obj[colorField];
        }
      })
      .attr('transform', 'translate(0, 0)')
    *//*
    if (showLegend) {
      // Legend:
      var legendRectSize = 13;
      var legendSpacing = 7;
      var legend = svg.selectAll('.legend') //the legend and placement
        .data(color.domain())
        .enter()
        .append('g')
        .attr('class', 'circle-legend')
        .attr('transform', function (d, i) {
          var height = legendRectSize + legendSpacing;
          var offset = height * color.domain().length / 2;
          var horz = -2 * legendRectSize - 55;
          var vert = i * height - offset;
          return 'translate(' + horz + ',' + vert + ')';
        });
      legend.append('circle') //keys
        .style('fill', color)
        .style('stroke', color)
        .attr('cx', 0)
        .attr('cy', 0)
        .attr('r', '.5rem');
      legend.append('text') //labels
        .attr('x', legendRectSize + legendSpacing)
        .attr('y', legendRectSize - legendSpacing)
        .text(function (d) {
          return `${d}`;
        });

      
    }*/
  }
}
